import { useState, useEffect } from 'react';

const EMPTY = '';
const ERROR_MESSSAGE_TIMEOUT = 5000;  //IN MILISECONDS
const useMessage = () => {
  const [message, setMessage] = useState(EMPTY);

  useEffect(() => {
    let messageTimeout;
    if (message) {
      messageTimeout = setTimeout(() => {
        setMessage(EMPTY);
      }, ERROR_MESSSAGE_TIMEOUT);
    }
    return () => clearTimeout(messageTimeout);
  }, [message]);

  return { message, setMessage };
};

export default useMessage;
