import React from 'react';
import styles from './DialogPage.module.css';
import { Box } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import { Help, Navbar, Sidebar } from '../../components';
import { useSelector } from 'react-redux';

const Dialog = () => {
  const { siteId } = useParams(); 
  const role = useSelector((state) => state.user.role);

  return (
    <Box className={styles.homePage}>

      <Navbar siteId={siteId}/>

      <Box className={styles.homeContainer} >


        <Box className={styles.sidebar}>
          <Sidebar role={role} siteId={siteId}/>
        </Box>



        <Box className={styles.dashboard} >
          <Outlet context={{ siteId }}/>
        </Box>


        <Box className={styles.help}>
          <Help />
        </Box>


      </Box>

    </Box>
  );
};

export default Dialog;
