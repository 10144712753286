import { useState, useEffect, useCallback } from 'react';

const useTimer = () => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prev => prev - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const startTimer = useCallback((duration) => {
    setTimer(duration);
  }, []);

  const resetTimer = useCallback(() => {
    setTimer(0);
  }, []);

  return { timer, startTimer, resetTimer };
};

export default useTimer;
