import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Typography, Tabs, Tab, Box, Grid2, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import styles from './Maintenance.module.css';
import CustomList from '../customTransferList';
import { useOutletContext } from 'react-router-dom';
import GlobalBackdrop from '../backdrop';
import { enterDevicesInMaintenance, exitDevicesFromMaintenance, getDevicesOfSite } from '../../apiCalls/device';

const ACTION = {
  ENTER: 'enter',
  EXIT: 'exit',
};

const MODE = {
  OPERATIONAL: 'OPERATIONAL',
  MAINTENANCE: 'MAINTENANCE',
};

const GRID_DIRECTION = {
  ROW: "row",
  COLUMN: "column"
}

const BUTTON = {
  SIZE_SMALL: 'small',
  VARIANT_CONTAINED: 'contained',
  VARIANT_OUTLINED: 'outlined',
};

const TITLE = {
  ACTION_ENTER: 'Devices in Operational Mode',
  ACTION_EXIT: 'Devices in Maintenance Mode',
};

const SUBMIT_BUTTON_TEXT = {
  ACTION_ENTER: 'Enter Maintenance',
  ACTION_EXIT: 'Exit Maintenance',
};

const CUSTOM_LIST_CARD_TITLE = {
  LEFT_CARD: 'Choices',
  RIGHT_CARD: 'Chosen',
};

const TABS_LABEL = {
  STATUS: 'Status',
  ENTER: 'Enter Maintenance',
  EXIT: 'Exit Maintenance',
}

const DATA_GRID_COLUMNS_FEILDS = {
  NAME: 'name',
  MODE: 'mode',
  ID: 'id',
}

const DATA_GRID_COLUMNS_HEADER_NAME = {
  NAME: 'Device Name',
  MODE: 'Mode',
  ID: 'Device ID',
}

const not = (a, b) => a.filter((value) => !b.includes(value));
const intersection = (a, b) => a.filter((value) => b.includes(value));
const union = (a, b) => [...a, ...not(b, a)];

const Maintainance = () => {
  const { siteId } = useOutletContext();
  const [action, setAction] = useState(ACTION.ENTER);
  const [tabIndex, setTabIndex] = useState(0);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [checked, setChecked] = useState([]);
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);

  
  const fetchDevices = useCallback(async () => {
    try {
      setLoading(true);
      const devices = await getDevicesOfSite(siteId);
      setDevices(devices);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [siteId]);
  
  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);
  
  const filteredDevices = useMemo(() => {
    return devices.filter((device) =>
      (action === ACTION.ENTER && device.mode === MODE.OPERATIONAL) ||
      (action === ACTION.EXIT && device.mode === MODE.MAINTENANCE)
    );
  }, [devices, action]);

  useEffect(() => {
    setLeft(filteredDevices);
    setRight([]);
    setChecked([]);
  }, [filteredDevices]);




  //handleSubmit
  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (action === ACTION.ENTER) {
        await enterDevicesInMaintenance({ deviceIds: rightChecked, siteId });
      } else if (action === ACTION.EXIT) {
        await exitDevicesFromMaintenance({ deviceIds: rightChecked, siteId });
      }
      fetchDevices();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const leftChecked = intersection(checked, left.map((device) => device.id));
  const rightChecked = intersection(checked, right.map((device) => device.id));

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) =>
    intersection(checked, items?.map((device) => device.id)).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items.map((device) => device.id)));
    } else {
      setChecked(union(checked, items.map((device) => device.id)));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(left.filter((device) => leftChecked.includes(device.id))));
    setLeft(not(left, left.filter((device) => leftChecked.includes(device.id))));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(right.filter((device) => rightChecked.includes(device.id))));
    setRight(not(right, right.filter((device) => rightChecked.includes(device.id))));
    setChecked(not(checked, rightChecked));
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    if (newValue === 1) {
      setAction(ACTION.ENTER);
    } else if (newValue === 2) {
      setAction(ACTION.EXIT);
    }
  };

  const columns = [
    { field: DATA_GRID_COLUMNS_FEILDS.ID, headerName: DATA_GRID_COLUMNS_HEADER_NAME.ID, flex: 1, minWidth: 150 },
    { field: DATA_GRID_COLUMNS_FEILDS.NAME, headerName: DATA_GRID_COLUMNS_HEADER_NAME.NAME, flex: 1, minWidth: 150 },
    { field: DATA_GRID_COLUMNS_FEILDS.MODE, headerName: DATA_GRID_COLUMNS_HEADER_NAME.MODE, flex: 1, minWidth: 150 },
  ];

  return (
    <Paper className={styles.maintainancePaper}>
      <Box className={styles.tabBox}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label={TABS_LABEL.STATUS} />
          <Tab label={TABS_LABEL.ENTER} />
          <Tab label={TABS_LABEL.EXIT} />
        </Tabs>
      </Box>

      {/* Status Tab - DataGrid to show devices and their modes */}
      {tabIndex === 0 && (
        <Box>
          <Typography className={styles.title}>Devices Status</Typography>

          <DataGrid rows={devices} columns={columns} pageSize={5} disableRowSelectionOnClick autoHeight />

        </Box>
      )}

      {/* Enter/Exit Tab Content */}
      {tabIndex !== 0 && (
        <>
          <Typography className={styles.title}>
            {action === ACTION.ENTER ? TITLE.ACTION_ENTER : TITLE.ACTION_EXIT}
          </Typography>
          <Grid2 container spacing={2} className={styles.gridContainer} direction={{ xs: GRID_DIRECTION.COLUMN, md: GRID_DIRECTION.COLUMN, lg: GRID_DIRECTION.ROW }}>
            <Grid2><CustomList title={CUSTOM_LIST_CARD_TITLE.LEFT_CARD} items={left} checked={checked} handleToggle={handleToggle} handleToggleAll={handleToggleAll} numberOfChecked={numberOfChecked} /></Grid2>
            <Grid2>
              <Grid2 container spacing={2} direction={GRID_DIRECTION.COLUMN} className={styles.actionButtons}>
                <Button
                  variant={BUTTON.VARIANT_OUTLINED}
                  size={BUTTON.SIZE_SMALL}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                >
                  &gt;
                </Button>
                <Button
                  variant={BUTTON.VARIANT_OUTLINED}
                  size={BUTTON.SIZE_SMALL}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                >
                  &lt;
                </Button>
              </Grid2>
            </Grid2>
            <Grid2><CustomList title={CUSTOM_LIST_CARD_TITLE.RIGHT_CARD} items={right} checked={checked} handleToggle={handleToggle} handleToggleAll={handleToggleAll} numberOfChecked={numberOfChecked} /></Grid2>

          </Grid2>
          <Grid2 size={{ xs: 12 }} className={styles.submitButton}>
            <Button
              variant={BUTTON.VARIANT_CONTAINED}
              size={BUTTON.SIZE_SMALL}
              onClick={handleSubmit}
              disabled={rightChecked.length === 0}
            >
              {action === ACTION.ENTER ? SUBMIT_BUTTON_TEXT.ACTION_ENTER : SUBMIT_BUTTON_TEXT.ACTION_EXIT}
            </Button>

          </Grid2>
        </>
      )}
      {loading && <GlobalBackdrop loading={loading} />}
    </Paper>
  );
};

export default Maintainance;