import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Chip, Grid2 } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { GRAFANA } from "../../config";
import styles from "./Dashboard.module.css";
import { getDeviceData } from "../../apiCalls/device";
import GlobalBackdrop from "../backdrop";


dayjs.extend(utc);
dayjs.extend(timezone);

// String Constants and Values
const timeZone = 'Asia/Kolkata';
const defaultDeviceDetails = [
    { label: "Device", value: "N/A" },
    { label: "Site ID", value: "N/A" },
    { label: "Mode", value: "N/A" },
    { label: "Assigned User", value: "N/A" },
    { label: "Total Consumption", value: "N/A" },
    { label: "Battery Percentage", value: "N/A" }
];

const DEVICE_INFO_SECTION_TITLE = 'Device Information';
const DEVICE_INFO_SECTION_TITLE_VARIANT = 'h5';
const DEVICE_INFO_ITEM_LABEL_VARIANT = 'subtitle1';
const DEVICE_INFO_ITEM_VALUE_VARIANT = 'body1';

const TIME_RANGE_SELECTOR_TITLE = 'Select Time Range';
const TIME_RANGE_SELECTOR_TITLE_VARIANT = 'h6';
const DATE_PICKER_LABEL = {
    FROM: 'From',
    TO: 'To'
}

const GRAPHS_TITLE = {
    LINE: 'Water Consumption Line Graph',
    BAR: 'Water Usage Bar Graph'
}
const GRAPHS_TITLE_VARIANT = 'h6';

const IFRAME_TITLE = {
    LINE: 'Grafana Water Consumption Line Graph',
    BAR: 'Grafana Water Usage Bar Graph'
}

const BAR_GRAPH_CHIPS_LABEL = {
    DAILY: 'Daily',
    WEEKLY: 'Week',
    MONTHLY: 'Month'
}

const BAR_GRAPH_CHIPS_VALUE = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month'
}

const PRIMARY_COLOR = 'primary';
const DEFAULT_COLOR = 'default';

// Helper function to build Grafana URL
const buildGrafanaUrl = (device, startDate, endDate, panelId, calculateRelativeTime) => {
    const from = calculateRelativeTime(startDate, endDate);
    const to = calculateRelativeTime(endDate, dayjs());

    // Properly encode the site_id and device.id
    const encodedSiteId = device.site_id;
    const encodedDeviceId = encodeURIComponent(device.id);

    const url = `${GRAFANA.URL}&var-bucket=${encodedSiteId}&var-device=${encodedDeviceId}&from=${from}&to=${to}&panelId=${panelId}`;
    return url;
};

// Dashboard Component
const Dashboard = ({ deviceId }) => {
    const [timeFrame, setTimeFrame] = useState("day");
    const [startDate, setStartDate] = useState(dayjs().tz(timeZone).subtract(1, 'days').startOf('day'));
    const [endDate, setEndDate] = useState(dayjs().tz(timeZone));
    const [device, setDevice] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchDeviceData = async () => {
            try {
                setLoading(true);
                if (!deviceId) throw new Error("Missing Device ID");
                const response = await getDeviceData(deviceId);
                setDevice(response);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }

        }
        fetchDeviceData();
    }, [deviceId]);


    device.site_id = device?.site_id?.replace(/:/g, "_");

    const handleTimeFrameChange = (frame) => {
        setTimeFrame(frame);

        switch (frame) {
            case "day":
                setStartDate(dayjs().tz(timeZone).subtract(7, 'days').startOf('day'));
                break;
            case "week":
                setStartDate(dayjs().tz(timeZone).subtract(8, 'weeks').startOf('day'));
                break;
            case "month":
                setStartDate(dayjs().tz(timeZone).subtract(8, 'months').startOf('day'));
                break;
            default:
                setStartDate(dayjs().tz(timeZone).subtract(7, 'days').startOf('day'));
                break;
        }
    };

    const calculateRelativeTime = (start, end) => {
        const diffInDays = end.diff(start, 'days');
        if (start.isSame(end, 'day')) {
            const startOfDay = start.startOf('day').toISOString();
            return `${startOfDay}`;
        } else if (diffInDays < 7) {
            return `now-${diffInDays}d`;
        } else if (diffInDays < 30) {
            return `now-${Math.floor(diffInDays / 7)}w`;
        } else {
            return `now-${Math.floor(diffInDays / 30) + 1}M`;
        }
    };


    const getPanelId = () => {
        switch (timeFrame) {
            case "day":
                return 1;
            case "week":
                return 2;
            case "month":
                return 3;
            default:
                return 1;
        }
    };

    return (
        <Box className={styles.dashboardContainer}>

            <DeviceInfoSection device={device || {}} />
            <TimeRangeSelector
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                timeZone={timeZone}
            />
            <LineGraphSection
                device={device || {}}
                startDate={startDate}
                endDate={endDate}
                calculateRelativeTime={calculateRelativeTime}
            />
            <BarGraphSection
                timeFrame={timeFrame}
                handleTimeFrameChange={handleTimeFrameChange}
                device={device || {}}
                startDate={startDate}
                endDate={endDate}
                calculateRelativeTime={calculateRelativeTime}
                getPanelId={getPanelId}
            />
            {loading && <GlobalBackdrop loading={loading} />}
        </Box>
    );
};

// Subcomponent 1: Device Info Section
const DeviceInfoSection = ({ device }) => {
    const deviceDetails = device
        ? [
            { label: "Device", value: device.name },
            { label: "Site ID", value: device.site_id },
            { label: "Mode", value: device.mode },
            { label: "User", value: device.assigned_user },
            { label: "Total Consumption", value: device.water_consumption },
            { label: "Battery Percentage", value: device.battery_percentage }
        ]
        : defaultDeviceDetails;

    return (
        <Paper className={styles.paperContainer}>
            <Typography variant={DEVICE_INFO_SECTION_TITLE_VARIANT} className={styles.deviceInfoSectionTitle} gutterBottom>
                {DEVICE_INFO_SECTION_TITLE}
            </Typography>
            <Grid2 container spacing={2}>
                {deviceDetails.map((detail, index) => (
                    <Grid2 key={index} size={{ xs: 12, md: 6 }} className={styles.deviceInfoItem} >
                        <Typography variant={DEVICE_INFO_ITEM_LABEL_VARIANT} className={styles.deviceInfoItemLabel}>
                            {detail.label}:
                        </Typography>
                        <Typography variant={DEVICE_INFO_ITEM_VALUE_VARIANT} className={styles.deviceInfoItemValue}>
                            {detail.value}
                        </Typography>
                    </Grid2>
                ))}
            </Grid2>
        </Paper>
    );
};

// Subcomponent 2: Time Range Selector
const TimeRangeSelector = ({ startDate, setStartDate, endDate, setEndDate, timeZone }) => {
    return (
        <Paper className={styles.paperContainer}>
            <Typography variant={TIME_RANGE_SELECTOR_TITLE_VARIANT} gutterBottom className={styles.timeRangeSelectorTitle}>
                {TIME_RANGE_SELECTOR_TITLE}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>


                <DatePicker
                    className={styles.datePicker}
                    label={DATE_PICKER_LABEL.FROM}
                    value={startDate}
                    onChange={(newValue) => {
                        if (newValue && newValue.isBefore(endDate)) {
                            setStartDate(newValue.tz(timeZone).startOf('day'));
                        }
                    }}
                    minDate={dayjs().tz(timeZone).startOf('day').subtract(10, 'years')}
                    maxDate={endDate}
                />

                <DatePicker
                    className={styles.datePicker}
                    label={DATE_PICKER_LABEL.TO}
                    value={endDate}
                    onChange={(newValue) => {
                        if (newValue && newValue.isAfter(startDate)) {
                            setEndDate(newValue.tz(timeZone));
                        }
                    }}
                    minDate={startDate}
                    maxDate={dayjs().tz(timeZone)}
                />

            </LocalizationProvider>
        </Paper>
    );
};

// Subcomponent 3: Line Graph Section
const LineGraphSection = ({ device, startDate, endDate, calculateRelativeTime }) => {
    const grafanaUrl = buildGrafanaUrl(device, startDate, endDate, 4, calculateRelativeTime);
    return (
        <Paper className={styles.paperContainer}>
            <Typography variant={GRAPHS_TITLE_VARIANT} gutterBottom>
                {GRAPHS_TITLE.LINE}
            </Typography>
            <Box className={styles.graphContainer}>
                <iframe
                    src={grafanaUrl}
                    className={styles.iframe}
                    title={IFRAME_TITLE.LINE}
                />
            </Box>
        </Paper>
    );
};

// Subcomponent 4: Bar Graph Section
const BarGraphSection = ({ timeFrame, handleTimeFrameChange, device, startDate, endDate, calculateRelativeTime, getPanelId }) => {
    const grafanaUrl = buildGrafanaUrl(device, startDate, endDate, getPanelId(), calculateRelativeTime);
    return (
        <Paper className={styles.paperContainer}>
            <Typography variant={GRAPHS_TITLE_VARIANT} gutterBottom>
                {GRAPHS_TITLE.BAR}
            </Typography>
            <Box className={styles.chipContainer}>
                <Chip
                    label={BAR_GRAPH_CHIPS_LABEL.DAILY}
                    onClick={() => handleTimeFrameChange(BAR_GRAPH_CHIPS_VALUE.DAY)}
                    color={timeFrame === BAR_GRAPH_CHIPS_VALUE.DAY ? PRIMARY_COLOR : DEFAULT_COLOR}
                    className={styles.chip}
                />
                <Chip
                    label={BAR_GRAPH_CHIPS_LABEL.WEEKLY}
                    onClick={() => handleTimeFrameChange(BAR_GRAPH_CHIPS_VALUE.WEEK)}
                    color={timeFrame === BAR_GRAPH_CHIPS_VALUE.WEEK ? PRIMARY_COLOR : DEFAULT_COLOR}
                    className={styles.chip}
                />
                <Chip
                    label={BAR_GRAPH_CHIPS_LABEL.MONTHLY}
                    onClick={() => handleTimeFrameChange(BAR_GRAPH_CHIPS_VALUE.MONTH)}
                    color={timeFrame === BAR_GRAPH_CHIPS_VALUE.MONTH ? PRIMARY_COLOR : DEFAULT_COLOR}
                    className={styles.chip}
                />
            </Box>
            <Box className={styles.graphContainer}>
                <iframe
                    src={grafanaUrl}
                    className={styles.iframe}
                    title={IFRAME_TITLE.BAR}
                />
            </Box>
        </Paper>
    );
};

export default Dashboard;
