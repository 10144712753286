import React from 'react';
import styles from './HomePage.module.css';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Help, Navbar, Sidebar } from '../../components';
import { useSelector } from 'react-redux';

const Home = () => {
  const user = useSelector((state) => state.user);

  return (
    <Box className={styles.homePage}>

      <Navbar />

      <Box className={styles.homeContainer} >


        <Box className={styles.sidebar}>
          <Sidebar role={user.role}/>
        </Box>



        <Box className={styles.dashboard} >
          <Outlet context={{siteId:user.siteId}}/>
        </Box>


        <Box className={styles.help}>
          <Help />
        </Box>


      </Box>

    </Box>
  );
};

export default Home;
