import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography, FormControl, Grid2, Paper, Tooltip, Fab, TextField, DialogTitle, Dialog, IconButton, DialogContent, DialogActions } from '@mui/material';
import { validateMobileNumber } from '../../utils';
import { AddressInput, MobileNumberInput, NameInput } from '../inputFeilds';
import { useDispatch, useSelector } from 'react-redux';
import { Close, Edit } from '@mui/icons-material';
import styles from './UserProfile.module.css';
import { useSnackbarAlert } from '../../hooks';
import { setLogout, setUser } from '../../state';
import { deleteUser, getUserDetails, roleChangeRequest, updateUserDetails } from '../../apiCalls/user';
import GlobalBackdrop from '../backdrop';


const TEXTFIELD_PROPS = {
  fullWidth: true,
  required: true,
  variant: 'outlined',
};

const EMAIL_INPUT_PROPS = {
  ...TEXTFIELD_PROPS,
  type: 'email',
  name: 'email',
  id: 'email'
}

const MOBILE_INPUT_PROPS = {
  ...TEXTFIELD_PROPS,
  type: 'numeric',
  name: 'mobile',
};

const ADDRESS_INPUT_PROPS = {
  ...TEXTFIELD_PROPS,
  name: 'address',
  type: 'text',
};

const NAME_INPUT_PROPS = {
  ...TEXTFIELD_PROPS,
  name: 'name',
  type: 'text',
};

const ROLE_INPUT_PROPS = {
  ...TEXTFIELD_PROPS,
  name: 'role',
  type: 'text',
  id: 'role',
}

const TITLE = 'User Profile';
const INPUT_FEILDS_LABELS = {
  NAME: 'Name',
  MOBILE: 'Mobile Number',
  ADDRESS: 'Address',
  EMAIL: 'Email',
  ROLE: 'Role'
}

const EMPTY = '';

const BUTTON_TEXT = {
  SAVE_CHANGES: 'Save Changes',
  DELETE: 'Delete Account',
  SUBMIT: 'Submit'
}

const ROLE = {
  SITE_ADMIN: 'site_admin',
  CONSUMER: 'consumer',
  SUPER_ADMIN: 'super_admin'
}

const ROLE_CHANGE_REQUEST_TEXT_TO_CONSUMER = 'Request to become consumer';
const ROLE_CHANGE_REQUEST_TEXT_TO_SITE_ADMIN = 'Request to become site admin';

const DIALOG_TITLE = 'Confirmation'

const EDIT_BUTTON_TOOLTIP = {
  TITLE: 'Edit Details',
  PLACEMENT: 'bottom'
}

const FAB_ICON = {
  COLOR_PRIMARY: 'primary',
  COLOR_DEFAULT: 'default',
  ARIA_LABEL: 'edit',
}

const FORM_CONTROL_VARIANT = 'outlined';
const TYPOGRAPHY_VARIANT = {
  BODY_1: 'body1',
  BODY_2: 'body2',
  H6: 'h6',
};

const BUTTON_COLOR = {
  PRIMARY: 'primary',
  ERROR: 'error',
}

const BUTTON_VARIANT = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
}

const DIALOG = {
  CLOSE_REASON: 'backdropClick',
  ARIA_LABELED_BY: 'request-dialog-title',
  TITLE_ID: 'request-dialog-title',
  CLOSE_ICON_ARIA_LABEL: 'close',
}


const UserProfile = ({ userId }) => {
  const user = useSelector((state) => state.user);
  const loginedRole = user.role;

  const dispatch = useDispatch();

  // State variables
  const [editMode, setEditMode] = useState(false);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {

    setOpenDialog(false);
  };

  const { showSnackbar, SnackbarComponent } = useSnackbarAlert();

  // Individual fields state
  const [name, setName] = useState(EMPTY);
  const [mobile, setMobile] = useState(EMPTY);
  const [address, setAddress] = useState(EMPTY);

  // Fetch user details by ID when the component mounts
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setLoading(true);
        const response = await getUserDetails(userId);
        setUserData(response);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
      finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [userId]);

  // Update form fields when user data is fetched
  useEffect(() => {
    if (userData) {
      setName(userData.name || EMPTY);
      setMobile(userData.mobile_number || EMPTY);
      setAddress(userData.address || EMPTY);
    }
  }, [userData]);

  // Validation
  const isMobileValid = useMemo(() => validateMobileNumber(mobile), [mobile]);
  const isNameValid = useMemo(() => name?.length >= 4, [name]);
  const isAddressValid = useMemo(() => address?.length >= 10, [address]);
  const isFormValid = useMemo(() => isNameValid && isAddressValid && isMobileValid, [isNameValid, isAddressValid, isMobileValid]);

  // Handlers
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await updateUserDetails({
        name,
        email: userData.email,
        mobile_number: mobile,
        address
      });
      if (user.userId === response.id) dispatch(setUser(response));

    } catch (error) {
      console.error('Error updating user details:', error);
    } finally {
      setEditMode(false);
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await deleteUser(userData.id);
      dispatch(setLogout());
    } catch (error) {
      console.error('Error deleting user:', error);
    }

  };

  const handleRoleChangeRequest = async () => {
    try {
      setLoading(true);
      const requested_role = userData.current_role === ROLE.SITE_ADMIN ? ROLE.CONSUMER : userData.current_role === ROLE.CONSUMER ? ROLE.SITE_ADMIN : EMPTY;
      await roleChangeRequest({ user_id: userData.id, requested_role });
      showSnackbar('Role change request sent successfully', 'success');
    } catch (error) {
      console.error('Error sending role change request:', error);
      showSnackbar('Error sending role change request', 'error');
    } finally {
      setOpenDialog(false);
      setLoading(false);
    }
  }

  return (
    <Paper className={styles.userProfilePaper}>
      <Box className={styles.userProfileHeader}>
        <Typography className={styles.userProfileTitle}>
          {TITLE}
        </Typography>
        <Tooltip title={EDIT_BUTTON_TOOLTIP.TITLE} placement={EDIT_BUTTON_TOOLTIP.PLACEMENT}>
          <Fab
            color={editMode ? FAB_ICON.COLOR_PRIMARY : FAB_ICON.COLOR_DEFAULT}
            aria-label={FAB_ICON.ARIA_LABEL}
            className={styles.userProfileEditButton}
            onClick={() => setEditMode(!editMode)}
          >
            <Edit />
          </Fab>
        </Tooltip>
      </Box>

      <form onSubmit={handleSubmit} className={styles.userProfileForm}>
        <Grid2 container spacing={3}>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <FormControl fullWidth variant={FORM_CONTROL_VARIANT}>
              <Typography className={styles.inputFeildsLabel}>
                {INPUT_FEILDS_LABELS.NAME}
              </Typography>
              <NameInput
                {...NAME_INPUT_PROPS}
                name={name || EMPTY}
                setName={setName}
                isSubmitting={!editMode}
              />
            </FormControl>
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6 }}>
            <FormControl fullWidth variant={FORM_CONTROL_VARIANT}>
              <Typography className={styles.inputFeildsLabel}>
                {INPUT_FEILDS_LABELS.EMAIL}
              </Typography>
              <TextField
                {...EMAIL_INPUT_PROPS}
                value={userData.email}
                slotProps={{
                  input: {
                    readOnly: true,
                  },
                }}
              />
            </FormControl>
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6 }}>
            <FormControl fullWidth variant={FORM_CONTROL_VARIANT}>
              <Typography className={styles.inputFeildsLabel}>
                {INPUT_FEILDS_LABELS.MOBILE}
              </Typography>
              <MobileNumberInput
                {...MOBILE_INPUT_PROPS}
                mobile={mobile || EMPTY}
                setMobile={setMobile}
                isMobileValid={isMobileValid}
                isSubmitting={!editMode}
              />
            </FormControl>
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6 }}>
            <FormControl fullWidth variant={FORM_CONTROL_VARIANT}>
              <Typography className={styles.inputFeildsLabel}>
                {INPUT_FEILDS_LABELS.ADDRESS}
              </Typography>
              <AddressInput
                {...ADDRESS_INPUT_PROPS}
                address={address || EMPTY}
                setAddress={setAddress}
                isSubmitting={!editMode}
              />
            </FormControl>
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6 }}>
            <FormControl fullWidth variant={FORM_CONTROL_VARIANT}>
              <Typography className={styles.inputFeildsLabel}>
                {INPUT_FEILDS_LABELS.ROLE}
              </Typography>
              <TextField
                {...ROLE_INPUT_PROPS}
                value={userData.current_role || EMPTY}
                slotProps={{
                  input: {
                    readOnly: true,
                  },
                }}
              />
            </FormControl>
          </Grid2>

          {(loginedRole === ROLE.SITE_ADMIN || loginedRole === ROLE.SUPER_ADMIN) && (
            <Grid2
              size={{ xs: 12, sm: 6 }}
              container
              className={styles.gridContainer}
            >
              <Typography
                variant={TYPOGRAPHY_VARIANT.BODY_2}
                className={styles.roleChangeLink}
                onClick={handleClickOpen}
              >
                {userData.current_role === ROLE.SITE_ADMIN
                  ? ROLE_CHANGE_REQUEST_TEXT_TO_CONSUMER
                  : ROLE_CHANGE_REQUEST_TEXT_TO_SITE_ADMIN}
              </Typography>
            </Grid2>
          )}
        </Grid2>

        <Box className={styles.userProfileButtonContainer}>
          <Button
            variant={BUTTON_VARIANT.CONTAINED}
            color={BUTTON_COLOR.PRIMARY}
            onClick={handleSubmit}
            className={styles.userProfileButton}
            disabled={!isFormValid || !editMode}
          >
            {BUTTON_TEXT.SAVE_CHANGES}
          </Button>

          <Button
            variant={BUTTON_VARIANT.OUTLINED}
            color={BUTTON_COLOR.ERROR}
            onClick={handleDeleteAccount}
            className={styles.userProfileButton}
          >
            {BUTTON_TEXT.DELETE}
          </Button>
        </Box>
      </form>

      {/* Dialog Box */}
      <Dialog
        open={openDialog}
        onClose={(event, reason) => {
          if (reason !== DIALOG.CLOSE_REASON) {
            handleCloseDialog();
          }
        }}
        aria-labelledby={DIALOG_TITLE.ARIA_LABELED_BY}
        fullWidth
        disableEscapeKeyDown // Prevent dialog from closing with Escape key
      >
        <DialogTitle id={DIALOG.TITLE_ID} className={styles.dialogTitle}>
          <Typography variant={TYPOGRAPHY_VARIANT.H6}>{DIALOG_TITLE}</Typography>
          <IconButton
            aria-label={DIALOG_TITLE.CLOSE_ICON_ARIA_LABEL}
            onClick={handleCloseDialog}
            className={styles.closeIconButton}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Box >
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
              <strong>Email:</strong> {userData.email}
            </Typography>
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
              <strong>Current Role:</strong> {userData.current_role}
            </Typography>
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
              <strong>Request for:</strong>{EMPTY}
              {userData.current_role === ROLE.SITE_ADMIN
                ? ROLE.CONSUMER
                : ROLE.SITE_ADMIN}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRoleChangeRequest}>{BUTTON_TEXT.SUBMIT}</Button>
        </DialogActions>
      </Dialog>

      {SnackbarComponent}
      <GlobalBackdrop loading={loading} />
    </Paper>
  );
};

export default UserProfile;
