import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab, Typography, Paper } from "@mui/material";
import Dashboard from "../dashboard";
import styles from './UserDevices.module.css'; // Import the CSS file
import { getDevicesOfUser } from "../../apiCalls/device";
import GlobalBackdrop from "../backdrop";


const MESSAGE_FOR_NO_DEVICES = "No devices registered for user";
const TABS_ARIA_LABEL = "user devices tabs";

const UserDevices = ({ userId }) => {
    const [selectedDeviceId, setSelectedDeviceId] = useState(null);
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleChange = (event, newValue) => {
        setSelectedDeviceId(newValue);
    };

    useEffect(() => {
        const fetchDevices = async () => {
            try {
                setLoading(true);
                const devices = await getDevicesOfUser(userId);
                setDevices(devices);
                if (devices?.length > 0) {
                    setSelectedDeviceId(devices[0].id);
                }
            } catch (error) {
                console.error( error);
            } finally {
                setLoading(false);
            }
        };
    
        if (userId) { 
            fetchDevices();
        }
    }, [userId]);  
    

    return (
        <Paper className={styles.userDevicesPaper}>
            {devices.length === 0 ? (
                <Typography className={styles.noDevicesMessage}>
                    {MESSAGE_FOR_NO_DEVICES}
                </Typography>
            ) : (
                <>
                    <Box className={styles.deviceTabs}>
                        <Tabs value={selectedDeviceId || false} onChange={handleChange} aria-label={TABS_ARIA_LABEL}>
                            {devices.map((device) => (
                                <Tab key={device?.id} label={device?.id} value={device?.id} />
                            ))}
                        </Tabs>
                    </Box>
                    <Box className={styles.deviceDashboard}>
                        {selectedDeviceId && (
                            <Dashboard deviceId={selectedDeviceId} />
                        )}
                    </Box>
                </>
            )}
            <GlobalBackdrop loading={loading} />
        </Paper>
    );
};

export default UserDevices;
