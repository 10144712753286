import React from 'react';
import { BackdropProvider } from './BackdropContext';

export const ContextProvider = ({ children }) => {
  return (
      <BackdropProvider>
        {children}
      </BackdropProvider>
  );
};
