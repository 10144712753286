import { API_ENDPOINTS } from "../config/apiEndpoints";
import api from "../utils";

const AEP_TO_GET_ALL_SITES = API_ENDPOINTS.GET_ALL_SITES_API;
const AEP_TO_GET_ALL_CONSUMERS = API_ENDPOINTS.GET_CONSUMERS_OF_SITE_API;
const REQUESTS_API_URL = API_ENDPOINTS.ROLE_CHANGE_PENDING_REQUESTS_OF_SITE_API;
const APPROVED_API_URL = API_ENDPOINTS.ROLE_CHANGE_PROCESSED_REQUESTS_OF_SITE_API;
const APPROVE_REQUEST_API_URL = API_ENDPOINTS.APPROVE_ROLE_CHANGE_REQUEST_API;
const REJECT_REQUEST_API_URL = API_ENDPOINTS.REJECT_ROLE_CHANGE_REQUEST_API;

const AEP_TO_GET_ALL_FIRMWARE_VERSIONS_OF_SITE = API_ENDPOINTS.FIRMWARE_VERSIONS_OF_SITE_API;
const AEP_FOR_GET_SITE_DETAILS = API_ENDPOINTS.GET_SITE_DETAILS_API;
const AEP_FOR_UPDATE_SITE_DETAILS = API_ENDPOINTS.UPDATE_SITE_DETAILS_API;
const AEP_FOR_GET_FIRMWARE_VERSIONS = API_ENDPOINTS.GET_FIRMWARE_VERSIONS_API;
const AEP_FOR_REGISTER_SITE = API_ENDPOINTS.REGISTER_SITE_API;


export const registerNewSite = async (siteData) => {
    try {
       await api.post(AEP_FOR_REGISTER_SITE, siteData);
    } catch (error) {
        throw error
    }
}

export const getSiteDetails = async (siteId) => {
    try {
        const encodedSiteId = encodeURIComponent(siteId);
        const response = await api.get(`${AEP_FOR_GET_SITE_DETAILS}${encodedSiteId}`);
        return response.data.data;
    } catch (error) {
        throw error
    }
}

export const getAllSites = async () => {
    try {
        const response = await api.get(AEP_TO_GET_ALL_SITES);
        return response.data.data;
    } catch (error) {
        throw error
    }
};

export const getConsumersOfSite = async (siteId) => {
    try {
        const encodedSiteId = encodeURIComponent(siteId);
        const response = await api.get(`${AEP_TO_GET_ALL_CONSUMERS}${encodedSiteId}`);
        console.log(response.data.data);
        return response.data.data;
    } catch (error) {
        throw error
    }
}

export const getRoleChangeRequests = async (siteId) => {
    try {
        const encodedSiteId = encodeURIComponent(siteId);
        const response = await api.get(`${REQUESTS_API_URL}${encodedSiteId}`);
        return response.data.data;
    } catch (error) {
        throw error
    }
}

export const getProcessedRoleChangeRequests = async (siteId) => {
    try {
        const encodedSiteId = encodeURIComponent(siteId);
        const response = await api.get(`${APPROVED_API_URL}${encodedSiteId}`);
        return response.data.data;
    } catch (error) {
        throw error
    }
}

export const approveRoleChangeRequest = async (rowData) => {
    try {
        const { id: user_id, currentRole: current_role, requestedRole: requested_role } = rowData;
        await api.post(APPROVE_REQUEST_API_URL, { user_id, current_role, requested_role });
    } catch (error) {
        throw error
    }
}

export const rejectRoleChangeRequest = async (rowData) => {
    try {
        const { id: user_id, currentRole: current_role, requestedRole: requested_role } = rowData;
        await api.post(REJECT_REQUEST_API_URL, { user_id, current_role, requested_role });
    } catch (error) {
        throw error
    }
}

export const getFirmwareVersionsOfSite = async (siteId) => {
    try {
        const encodedSiteId = encodeURIComponent(siteId);
        const response = await api.get(`${AEP_TO_GET_ALL_FIRMWARE_VERSIONS_OF_SITE}${encodedSiteId}`);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}

export const updateSiteDetails=async (siteData)=>{
    try{
        await api.patch(AEP_FOR_UPDATE_SITE_DETAILS,siteData);
    }catch(error){
        throw error;
    }
}

export const getFirmwareVersions = async () => {
    try {
        const response = await api.get(AEP_FOR_GET_FIRMWARE_VERSIONS);
        return response.data.data;
    } catch (error) {
        throw error
    }
}