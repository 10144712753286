// RoleManagement.js

import React, { useCallback, useEffect, useState } from 'react';
import { Box, Tabs, Tab, Paper, Button, Chip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import styles from './RoleManagement.module.css';
import { useOutletContext } from 'react-router-dom';
import { approveRoleChangeRequest, getProcessedRoleChangeRequests, getRoleChangeRequests, rejectRoleChangeRequest } from '../../apiCalls/sites';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../state';
import GlobalBackdrop from '../backdrop';

const REQUESTS_TAB_LABEL = "Requests";
const APPROVED_REJECTED_TAB_LABEL = "Approved/Rejected";

const RoleManagement = () => {
    const user=useSelector((state) => state.user);
    const dispatch = useDispatch();
    const {siteId}=useOutletContext();
    const [tab, setTab] = useState(0);
    const [requests,setRequests]=useState([]);
    const [processed,setProcessed]=useState([]);
    const [loading,setLoading]=useState(false);

    const fetchRequests = useCallback(async () => {
        try {
            const requests = await getRoleChangeRequests(siteId);
            setRequests(requests);
        } catch (error) {
            console.error(error);
        }
    }, [siteId]);

    const fetchProcessed = useCallback(async () => {
        try {
            const processed = await getProcessedRoleChangeRequests(siteId);
            setProcessed(processed);
        } catch (error) {
            console.error(error);
        }
    }, [siteId]);

    useEffect(() => {
        fetchRequests();
        fetchProcessed();
    }, [fetchRequests, fetchProcessed]);
    

    const handleApprove = async (rowData) => {
        try {
            setLoading(true);
            await approveRoleChangeRequest(rowData);
            if (rowData.id === user.userId) {
                dispatch(setUser({ current_role: rowData.requested_role }));
            }
            fetchRequests();
            fetchProcessed();
        } catch (error) {
            console.error("Error approving request", error);
        } finally {
            setLoading(false);
        }
    };
    
    const handleReject = async (rowData) => {
        try {
            setLoading(true);
            await rejectRoleChangeRequest(rowData);
            fetchRequests();
        } catch (error) {
            console.error("Error rejecting request", error);
        } finally {
            setLoading(false);
        }
    };
    
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const requestColumns = [
        { field: 'name', headerName: 'Username', flex: 1,minWidth: 150 },
        { field: 'current_role', headerName: 'Current Role', flex: 1,minWidth: 140 },
        { field: 'requested_role', headerName: 'Requested Role', flex: 1,minWidth: 140 },
        {
            field: 'approve',
            headerName: 'Approve',
            flex: 1,
            minWidth: 140,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleApprove(params.row)}
                >
                    Approve
                </Button>
            ),
        },
        {
            field: 'reject',
            headerName: 'Reject',
            flex: 1,
            minWidth: 140,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleReject(params.row)}
                >
                    Reject
                </Button>
            ),
        },
    ];

    const approvedColumns = [
        { field: 'name', headerName: 'Username', flex: 1 ,minWidth: 150 },
        { field: 'current_role', headerName: 'Current Role', flex: 1,minWidth: 150 },
        { field: 'requested_role', headerName: 'Requested Role', flex: 1,minWidth: 150 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {
                const statusColor = params.row.status === 'approved' ? 'success' : 'error';
                return (
                    <Chip
                        label={params.row.status.toUpperCase()}
                        color={statusColor}
                        variant="contained"
                        className={styles.statusChip}
                    />
                );
            },
        },
    ];

    return (
        <Paper className={styles.paper}>
            <Box className={styles.tabBox}>
                <Tabs value={tab} onChange={handleChange} aria-label="role management tabs">
                    <Tab label={REQUESTS_TAB_LABEL} />
                    <Tab label={APPROVED_REJECTED_TAB_LABEL} />
                </Tabs>
            </Box>
            <Box className={styles.dataGridBox}>
                {tab === 0 ? (
                    <DataGrid
                        rows={requests}
                        columns={requestColumns}
                        pageSize={6}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                        autoHeight
                        />
                    ) : (
                        <DataGrid
                        rows={processed}
                        columns={approvedColumns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                        autoHeight
                    />
                )}
            </Box>
            {loading && <GlobalBackdrop loading={loading}/>}
        </Paper>
    );
};

export default RoleManagement;
