import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './RegisterPage.module.css';
import AuthForm from '../../components/authForm';

const TYPOGRAPHY_VARIANT = "h4";
const TYPOGRAPHY_COMPONENT = "h2";
const REGISTER_PAGE_TITLE = "Register";
const AUTH_FORM_TYPE = "register";

const RegisterPage = () => {

  return (

      <Box className={styles.registerPage}>
        <Box className={styles.registerContainer}>
          <Typography variant={TYPOGRAPHY_VARIANT} component={TYPOGRAPHY_COMPONENT}>
            {REGISTER_PAGE_TITLE}
          </Typography>
          <AuthForm formType={AUTH_FORM_TYPE} />
        </Box>
      </Box>
  );
};

export default RegisterPage;
