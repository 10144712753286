import React, { useState, useMemo } from 'react';
import { Box, Button, Typography, FormControl, Paper, Divider } from '@mui/material';
import { useSnackbarAlert } from '../../hooks';
import styles from './SiteRegistration.module.css'; // CSS module for enhanced styling
import { AddressInput, GatewayIdInput, NameInput } from '../inputFeilds';
import { registerNewSite } from '../../apiCalls/sites';

// API endpoint for registration


const TITLE = "Register a New Site";

// TextField common properties
const TEXTFIELD_PROPS = {
  fullWidth: true,
  variant: 'outlined',
};

// Props for NameInput
const NAME_INPUT_PROPS = {
  ...TEXTFIELD_PROPS,
  required: true,
  name: 'name',
  type: 'text',
  placeholder: "Enter site name (e.g., Pune Metro Hub)",
};

// Props for AddressInput
const ADDRESS_INPUT_PROPS = {
  ...TEXTFIELD_PROPS,
  name: 'address',
  required: true,
  type: 'text',
  placeholder: "Enter site address (e.g., 123 Main St, San Francisco, CA 94105)",
};

// Props for GatewayIdInput
const GATEWAY_ID_INPUT_PROPS = {
  ...TEXTFIELD_PROPS,
  name: 'gtw_id',
  type: 'text',
  placeholder: "Enter gateway id (e.g., 1234-1234-1234-1234)",
};

const SUBMIT_BUTTON_PROPS = {
  type: 'submit',
  variant: 'contained',
  color: 'primary',
};

const BUTTON_TEXT = 'Register Site';

const INPUT_FEILDS_LABELS = {
  SITE_NAME: 'Site Name*',
  GTW_ID: 'Gateway ID',
  LOCATION: 'Site Location*',
};

const FORMCONTROL_VARIANT = "outlined";

// Main Component for Site Registration
const SiteRegistration = () => {
  // State variables
  const [siteName, setSiteName] = useState('');
  const [gtwId, setGtwId] = useState('');
  const [address, setAddress] = useState('');
  const { showSnackbar, SnackbarComponent } = useSnackbarAlert();

  // Validation
  const isNameValid = useMemo(() => siteName.length >= 4, [siteName]);
  const isAddressValid = useMemo(() => address.length >= 10, [address]);
  const isFormValid = useMemo(() => isNameValid && (isAddressValid), [ isNameValid, isAddressValid]);

  // Handler for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const siteData = {
        site_name: siteName,
        gtw_id: gtwId,
        location: address,
      }

      await registerNewSite(siteData);

      showSnackbar('Site registered successfully!', 'success');
      // Reset form fields
      setSiteName('');
      setGtwId('');
      setAddress('');

    } catch (error) {
      console.error('Error registering site:', error);
      showSnackbar('Error registering site: ' + (error.response?.data?.message || error.message), 'error');
    }
  };

  return (
    <Paper className={styles.container}>
      <Typography className={styles.title} gutterBottom>
        {TITLE}
      </Typography>
      <Divider className={styles.divider} />

      <form onSubmit={handleSubmit} className={styles.form}>
        <SiteNameInput siteName={siteName} setSiteName={setSiteName} />
        <GatewayIdInputField gtwId={gtwId} setGtwId={setGtwId} />
        <AddressInputField address={address} setAddress={setAddress} />

        <Box >
          <Button
            {...SUBMIT_BUTTON_PROPS}
            disabled={!isFormValid}
          >
            {BUTTON_TEXT}
          </Button>
        </Box>
      </form>

      {SnackbarComponent}
    </Paper>
  );
};

// Subcomponent for SiteName Input
const SiteNameInput = ({ siteName, setSiteName }) => (
  <FormControl fullWidth variant={FORMCONTROL_VARIANT} className={styles.formControl}>
    <Typography className={styles.inputFieldLabel}>
      {INPUT_FEILDS_LABELS.SITE_NAME}
    </Typography>
    <NameInput
      {...NAME_INPUT_PROPS}
      name={siteName}
      setName={setSiteName}
    />
  </FormControl>
);

// Subcomponent for Gateway ID Input
const GatewayIdInputField = ({ gtwId, setGtwId }) => (
  <FormControl fullWidth variant={FORMCONTROL_VARIANT} className={styles.formControl}>
    <Typography className={styles.inputFieldLabel}>
      {INPUT_FEILDS_LABELS.GTW_ID}
    </Typography>
    <GatewayIdInput
      value={gtwId}
      onChange={setGtwId}
      {...GATEWAY_ID_INPUT_PROPS}
    />
  </FormControl>
);

// Subcomponent for Address Input
const AddressInputField = ({ address, setAddress }) => (
  <FormControl fullWidth variant={FORMCONTROL_VARIANT} className={styles.formControl}>
    <Typography className={styles.inputFieldLabel}>
      {INPUT_FEILDS_LABELS.LOCATION}
    </Typography>
    <AddressInput
      {...ADDRESS_INPUT_PROPS}
      address={address}
      setAddress={setAddress}
    />
  </FormControl>
);

export default SiteRegistration;
