import { API_ENDPOINTS } from "../config/apiEndpoints";
import api from "../utils";

const AEP_TO_FETCH_DEVICES_OF_USER = API_ENDPOINTS.FETCH_DEVICES_OF_USER_API;
const AEP_TO_FETCH_DEVICE_DATA = API_ENDPOINTS.FETCH_DEVICE_DATA_API;
const AEP_TO_GET_ALL_DEVICES_OF_SITE = API_ENDPOINTS.GET_ALL_DEVICES_OF_A_SITE_API;

const AEP_TO_ASSIGN_DEVICE = API_ENDPOINTS.ASSIGN_DEVICE_TO_USER_API;
const AEP_TO_DEASSIGN_DEVICE = API_ENDPOINTS.DEASSIGN_DEVICE_API;

const AEP_FOR_MAINTENANCE_MODE = API_ENDPOINTS.MAINTENANCE_API;

const AEP_TO_INTIMATE_FIRMWARE = API_ENDPOINTS.INTIMATE_LATEST_FIRMWARE_VERSION_API;

const ACTION = {
    ENTER: 'enter',
    EXIT: 'exit',
  };

export const getDevicesOfUser = async (userId) => {
    try {
        const response = await api.get(`${AEP_TO_FETCH_DEVICES_OF_USER}${userId}`);
        return response.data.data;
    } catch (error) {
        throw error
    } 
};

export const getDeviceData = async (deviceId) => {
        try {
            const encodedDeviceId = encodeURIComponent(deviceId);
            const FINAL_API = `${AEP_TO_FETCH_DEVICE_DATA}${encodedDeviceId}`;
            const response = await api.get(FINAL_API);
            return response.data.data;
        } catch (err) {
            throw err;
        }
}

export const getDevicesOfSite = async (siteId) => { 
    try {
        const encodedSiteId = encodeURIComponent(siteId);
        const response = await api.get(`${AEP_TO_GET_ALL_DEVICES_OF_SITE}${encodedSiteId}`);
        return response.data.data;
    } catch (error) {   
        throw error;                
    }
}

export const assignDeviceToUser = async (deviceId, userId) => {
    try {
        await api.post(AEP_TO_ASSIGN_DEVICE, {device_id: deviceId, user_id: userId});
    } catch (err) {
        throw err;
    }
}

export const deassignDeviceFromUser = async (deviceId, userEmail) => {
    try {
        await api.post(AEP_TO_DEASSIGN_DEVICE, {device_id: deviceId, user_email: userEmail});
    } catch (err) {
        throw err;
    }
}

export const enterDevicesInMaintenance = async ({deviceIds, siteId  }) => {
    try {
        await api.post(AEP_FOR_MAINTENANCE_MODE, {site_id: siteId, action: ACTION.ENTER, devices: deviceIds});
    } catch (err) {
        throw err;
    }
}

export const exitDevicesFromMaintenance = async ({deviceIds, siteId  }) => {
    try {
        await api.post(AEP_FOR_MAINTENANCE_MODE, {site_id: siteId, action: ACTION.EXIT, devices: deviceIds});
    } catch (err) {
        throw err;
    }
}

export const intimateFirmwareVersion = async ({siteId, version}) => {
    try {
        await api.post(AEP_TO_INTIMATE_FIRMWARE, {site_id: siteId, version: version});
    } catch (err) {
        throw err;
    }
}
