import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './AddDetailsPage.module.css';
import UserDetailsForm from '../../components/completeUserDetails';

const TYPOGRAPHY_VARIANT = "h4";
const TYPOGRAPHY_COMPONENT = "h2";
const ADD_DETAILS_PAGE_TITLE = "Add Details";

const AddDetailsPage = () => {
  

    return (
      <Box className={styles.addDetailsPage}>
        <Box className={styles.addDetailsContainer}>
          <Typography variant={TYPOGRAPHY_VARIANT} component={TYPOGRAPHY_COMPONENT}>
            {ADD_DETAILS_PAGE_TITLE}
          </Typography>
          <UserDetailsForm />
        </Box>
      </Box>
  )
}

export default AddDetailsPage;