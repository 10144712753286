import React, { useEffect, useState } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Paper,
  MenuItem,
  Select,
  FormControl,
  Button,
  InputLabel,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useSnackbarAlert } from '../../hooks';
import { API_ENDPOINTS } from '../../config/apiEndpoints';
import api from '../../utils';
import styles from './Update.module.css';
import { getAllSites, getFirmwareVersions } from '../../apiCalls/sites';
import GlobalBackdrop from '../backdrop';

// API endpoints as constants
const AEP_FOR_UPDATE_FIRMWARE_VERSION = API_ENDPOINTS.UPDATE_FIRMWARE_VERSION_API;
const AEP_FOR_UPDATE_SOFTWARE_VERSION = API_ENDPOINTS.UPDATE_GATEWAY_SOFTWARE_VERSION_API;

// Constants for UI
const TABS_ARIA_LABEL = "update tabs";
const TABS_LABEL = {
  FIRMWARE: "Firmware",
  SOFTWARE: "Software",
};
const TABS_ID = {
  FIRMWARE: "tab-0",
  SOFTWARE: "tab-1",
};
const NO_SITES = "No sites available.";

// DataGrid column field names
const DATA_GRID_COLUMNS_FIELDS = {
  ID: "id",
  SITE_NAME: "name",
  FIRMWARE_VERSION: "firmware_versions",
  SOFTWARE_VERSION: "version",
  LOCATION: "location",
};

// DataGrid column header names
const DATA_GRID_COLUMNS_HEADER_NAME = {
  SITE_NAME: "Site Name",
  FIRMWARE_VERSION: "Firmware Version",
  SOFTWARE_VERSION: "Software Version",
  LOCATION: "Location",
};

// Button properties
const SUBMIT_BUTTON_PROPS = {
  variant: "contained",
  color: "primary",
  type: "submit",
};

const SNACKBAR_ALERT_SEVERITY = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
}

const SUBMIT_BUTTON_TEXT = "Update";
const FORM_CONTROL_SIZE = "small";
const FORM_CONTROL_INPUT_LABEL_ID = "version-select-label";
const FORM_CONTROL_INPUT_LABEL_TEXT = "Version";
const EMPTY = "";

const TabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box p={3}><Typography>{children}</Typography></Box>}
    </div>
  );
};

const Update = () => {
  const [tab, setTab] = useState(0);
  const [selectedFirmwareVersions, setSelectedFirmwareVersions] = useState({});
  const [selectedSiteIds, setSelectedSiteIds] = useState([]);
  const [selectedFirmwareVersionToUpdate, setSelectedFirmwareVersionToUpdate] = useState(null);
  const {showSnackbar, SnackbarComponent:SubmitSnackbarComponent}= useSnackbarAlert();
  const [siteOptions, setSiteOptions] = useState([]);
  const [firmwareVersions, setFirmwareVersions] = useState([]);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    const fetchSites = async () => {
      try {
        setLoading(true);
        const response = await getAllSites();  // Await the async call
        setSiteOptions(response);
      } catch (error) {
        console.error('Error fetching site options:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchFirmwareVersions = async () => {
      try {
        setLoading(true);
        const response = await getFirmwareVersions();  // Await the async call
        setFirmwareVersions(response);
      } catch (error) {
        console.error('Error fetching firmware versions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFirmwareVersions();
    fetchSites();
  }, []);
  const handleSubmit = async () => {
    if (!selectedFirmwareVersionToUpdate && tab === 0) {
      showSnackbar("Firmware version not selected!", SNACKBAR_ALERT_SEVERITY.WARNING);
      console.error("Version not selected!");
      return;
    }
    if (!selectedSiteIds.length) {
      showSnackbar("No site selected!", SNACKBAR_ALERT_SEVERITY.WARNING);
      console.error("No site selected!");
      return;
    }

    try {
      if (tab === 0) {
        // Call firmware update API
        await api.post(AEP_FOR_UPDATE_FIRMWARE_VERSION, {
          sites: selectedSiteIds,
          version: selectedFirmwareVersionToUpdate
        });
        showSnackbar("Firmware version updated successfully!", SNACKBAR_ALERT_SEVERITY.SUCCESS);
      } else if (tab === 1) {
        // Call software update API
        await api.post(AEP_FOR_UPDATE_SOFTWARE_VERSION, { sites: selectedSiteIds });
        showSnackbar("Software version updated successfully!", SNACKBAR_ALERT_SEVERITY.SUCCESS);
      }
      // Trigger success Snackbar
    } catch (error) {
      showSnackbar("Error updating version!", SNACKBAR_ALERT_SEVERITY.ERROR);
      console.error("Error updating version:", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleFirmwareVersionChange = (id, version) => {
    setSelectedFirmwareVersions((prev) => ({
      ...prev,
      [id]: version,
    }));
  };

  const handleSelectionChange = (newSelection) => {
    const selectedIds = newSelection;
    setSelectedSiteIds(selectedIds);
  };

  const handleVersionChange = (id, value) => {
    setSelectedFirmwareVersionToUpdate(value);
  };

  const firmwareColumns = [
    { field: DATA_GRID_COLUMNS_FIELDS.SITE_NAME, headerName: DATA_GRID_COLUMNS_HEADER_NAME.SITE_NAME, flex: 1, minWidth: 150 },
    { field: DATA_GRID_COLUMNS_FIELDS.LOCATION, headerName: DATA_GRID_COLUMNS_HEADER_NAME.LOCATION, flex: 1, minWidth: 150 },
    {
      field: DATA_GRID_COLUMNS_FIELDS.FIRMWARE_VERSION,
      headerName: DATA_GRID_COLUMNS_HEADER_NAME.FIRMWARE_VERSION,
      flex: 1,
      minWidth: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const versions = params.value || [];
        return (
          <VersionSelect
            id={params.id}
            versions={versions}
            selectedVersion={selectedFirmwareVersions[params.id]}
            handleVersionChange={handleFirmwareVersionChange}
          />
        );
      },
    },
  ];

  const softwareColumns = [
    { field: DATA_GRID_COLUMNS_FIELDS.SITE_NAME, headerName: DATA_GRID_COLUMNS_HEADER_NAME.SITE_NAME, flex: 1, minWidth: 150 },
    { field: DATA_GRID_COLUMNS_FIELDS.LOCATION, headerName: DATA_GRID_COLUMNS_HEADER_NAME.LOCATION, flex: 1, minWidth: 150 },
    { field: DATA_GRID_COLUMNS_FIELDS.SOFTWARE_VERSION, headerName: DATA_GRID_COLUMNS_HEADER_NAME.SOFTWARE_VERSION, flex: 1, minWidth: 150 },
  ];

  return (
    <Paper className={styles.updateContainer}>
      <Box className={styles.tabHeader}>
        <Tabs value={tab} onChange={handleTabChange} aria-label={TABS_ARIA_LABEL}>
          <Tab label={TABS_LABEL.FIRMWARE} id={TABS_ID.FIRMWARE} />
          <Tab label={TABS_LABEL.SOFTWARE} id={TABS_ID.SOFTWARE} />
        </Tabs>
        {tab === 0 && (
          <VersionSelect
            id={0}
            versions={firmwareVersions}
            selectedVersion={selectedFirmwareVersionToUpdate}
            handleVersionChange={handleVersionChange}
            isUpdate={true}
          />
        )}
      </Box>

      {/* Firmware Tab */}
      <TabPanel value={tab} index={0}>
        <DataGrid
          rows={siteOptions || []}
          columns={firmwareColumns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={handleSelectionChange}
          autoHeight
          components={{
            NoRowsOverlay: () => <Typography className={styles.noRowsOverlay}>{NO_SITES}</Typography>,
          }}
        />
      </TabPanel>

      {/* Software Tab */}
      <TabPanel value={tab} index={1}>
        <DataGrid
          rows={siteOptions || []}
          columns={softwareColumns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={handleSelectionChange}
          autoHeight
          components={{
            NoRowsOverlay: () => <Typography className={styles.noRowsOverlay}>{NO_SITES}</Typography>,
          }}
        />
      </TabPanel>

      <Button {...SUBMIT_BUTTON_PROPS} onClick={handleSubmit} >
        {SUBMIT_BUTTON_TEXT}
      </Button>
      {SubmitSnackbarComponent}
      {loading && <GlobalBackdrop loading={loading} />}
    </Paper>
  );
};

export default Update;

// Subcomponent for Firmware/Software Version Select
const VersionSelect = ({ id, versions, selectedVersion, handleVersionChange, isUpdate }) => {
  const defaultVersion = selectedVersion || (!isUpdate && versions[versions.length - 1]) || EMPTY; // Handle case where versions might be empty

  return (
    <Box className={styles.versionSelectContainer}>
      <FormControl className={styles.versionSelect} size={FORM_CONTROL_SIZE}>
        {isUpdate && <InputLabel id={FORM_CONTROL_INPUT_LABEL_ID}>{FORM_CONTROL_INPUT_LABEL_TEXT}</InputLabel>}
        <Select
          value={defaultVersion}
          onChange={(event) => handleVersionChange(id, event.target.value)}
          label={isUpdate ? FORM_CONTROL_INPUT_LABEL_TEXT : EMPTY}
        >
          {versions.map((version, index) => (
            <MenuItem key={index} value={version}>
              {version}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
