

export const API_ENDPOINTS = {
    SEND_EMAIL_OTP_API: 'api/v1/auth/send-email-otp',
    VERIFY_EMAIL_OTP_API: 'api/v1/auth/verify-email-otp',
    SEND_MOBILE_OTP_API: 'api/v1/auth/send-mobile-otp',
    VERIFY_MOBILE_OTP_API: 'api/v1/auth/verify-mobile-otp',
    CHECK_USER_EXIST_API: 'api/v1/auth/user-exist',
    GOOGLE_SIGN_IN_API: 'api/v1/auth/signin-with-google',
    COMPLETE_PROFILE_API: 'api/v1/users/complete-profile',
    UPDATE_USER_PROFILE_API: 'api/v1/users/update-profile',
    DELETE_USER_API: 'api/v1/users/delete-user-profile/',
    LOGOUT_API: 'api/v1/auth/logout',
    FETCH_DEVICE_DATA_API: 'api/v1/devices/get-device-info/',
    FETCH_DEVICES_OF_USER_API: 'api/v1/devices/get-all-devices-of-user/',
    GET_ALL_DEVICES_OF_A_SITE_API: 'api/v1/devices/get-all-devices-of-site/',
    ASSIGN_DEVICE_TO_USER_API: 'api/v1/users/assign-device',
    DEASSIGN_DEVICE_API: 'api/v1/users/unassign-device',
    GET_CONSUMERS_OF_SITE_API: 'api/v1/users/get-all-consumers-of-site/',
    INTIMATE_LATEST_FIRMWARE_VERSION_API: 'api/v1/firmwares/intimate-latest-firmware-version',
    FIRMWARE_VERSIONS_OF_SITE_API: 'api/v1/firmwares/get-all-firmware-versions-of-site/',
    MAINTENANCE_API: 'api/v1/devices/maintenance-mode',
    GET_ALL_SITES_API: 'api/v1/sites/get-all-sites',
    GET_USER_PROFILE_API: 'api/v1/users/get-user-profile-by-id/',
    GET_SITE_DETAILS_API: 'api/v1/sites/get-site-details/',
    UPDATE_SITE_DETAILS_API: 'api/v1/sites/update-site-details',
    REGISTER_SITE_API: 'api/v1/sites/create-site',
    ROLE_CHANGE_PENDING_REQUESTS_OF_SITE_API: '/api/v1/users/get-pending-requests-of-a-site/',
    ROLE_CHANGE_PROCESSED_REQUESTS_OF_SITE_API: '/api/v1/users/get-processed-requests-of-a-site/',
    ROLE_CHANGE_REQUEST_API: '/api/v1/users/role-change-request',
    APPROVE_ROLE_CHANGE_REQUEST_API: '/api/v1/users/approve-request',
    REJECT_ROLE_CHANGE_REQUEST_API: '/api/v1/users/reject-request',
    UPDATE_FIRMWARE_VERSION_API: '/api/v1/sites/latest-firmware-version',
    UPDATE_GATEWAY_SOFTWARE_VERSION_API: '/api/v1/sites/latest-gateway-version',
    GET_FIRMWARE_VERSIONS_API: '/api/v1/firmwares/get-all-firmware-versions',
    REFRESH_TOKEN_API: '/api/v1/auth/refresh-token',
};
