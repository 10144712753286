import React from "react";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import styles from "./Sidebar.module.css";

const SidebarItem = ({ icon: Icon, text, onClick, className }) => (
    <ListItemButton onClick={onClick} className={className}>
        {Icon && <ListItemIcon className={styles.listItemIcon}>
            <Icon />
        </ListItemIcon>}
        <ListItemText primary={text} />
    </ListItemButton>
);

export default SidebarItem;
