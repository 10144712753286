import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Logout, Menu } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography, useMediaQuery, Drawer } from "@mui/material";
import Sidebar from "../sidebar";
import styles from "./Navbar.module.css";
import FlexBetween from "../flexbetween";
import { ROUTES } from "../../config";
import { logout } from "../../apiCalls/auth";
import { setLogout } from "../../state";

//Contants
const ROUTE_TO_HOME_PAGE = ROUTES.HOME;
const NON_MOBILE_SCREEN_WIDTH = "( min-width: 700px )";
const LOGO_IMG_PATH = "/logo.png";
const ALT_TEXT_FOR_LOGO = "NUEZ TECH";
const LOGOUT_TOOLTIP_TEXT = "Logout";
const MENU_DRAWER_POSITION = "left";



// Main Navbar Component
const Navbar = ({siteId}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userName = useSelector((state) => state.user.name).toUpperCase();
  const role = useSelector((state) => state.user.role);
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const isNonMobileScreens = useMediaQuery(NON_MOBILE_SCREEN_WIDTH);

  const handleLogout=async()=>{
    try {
      await logout();
      dispatch(setLogout());
      navigate(ROUTES.LOGIN);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (isNonMobileScreens) {
      setIsMobileMenuToggled(false);
    }
  }, [isNonMobileScreens]);

  return (
    <Box className={styles.navbar}>
      <MenuButton isMobileMenuToggled={isMobileMenuToggled} setIsMobileMenuToggled={setIsMobileMenuToggled} />
      <Logo navigate={navigate} />
      <UserAndLogout userName={userName} handleLogout={handleLogout} />
      <DrawerComponent isMobileMenuToggled={isMobileMenuToggled} setIsMobileMenuToggled={setIsMobileMenuToggled} handleLogout={handleLogout} role={role} siteId={siteId}/>
    </Box>
  );
};

export default Navbar;

// Subcomponents

const MenuButton = ({ isMobileMenuToggled, setIsMobileMenuToggled }) => (
  <IconButton
    className={styles.menuButton}
    onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
  >
    <Menu />
  </IconButton>
);

const Logo = ({ navigate }) => (
  <Box className={styles.navbarImgBox}>
    <img
      src={LOGO_IMG_PATH}
      alt={ALT_TEXT_FOR_LOGO}
      className={styles.navbarImg}
      onClick={() => navigate(ROUTE_TO_HOME_PAGE)}
    />
  </Box>
);

const UserAndLogout = ({ userName, handleLogout }) => (
  <FlexBetween className={styles.nameAndLogOutBox}>
    <Typography>{userName}</Typography>
    <Tooltip title={LOGOUT_TOOLTIP_TEXT}>
      <IconButton onClick={handleLogout}>
        <Logout className={styles.navbarLogOutButton} />
      </IconButton>
    </Tooltip>
  </FlexBetween>
);

const DrawerComponent = ({ isMobileMenuToggled, setIsMobileMenuToggled, handleLogout, role, siteId }) => (
  <Drawer
    anchor={MENU_DRAWER_POSITION}
    open={isMobileMenuToggled}
    onClose={() => setIsMobileMenuToggled(false)}
  >
    <Sidebar
      isDrawer={true}
      handleLogout={handleLogout}
      setIsMobileMenuToggled={setIsMobileMenuToggled}
      role={role}
      siteId={siteId}
    />
  </Drawer>
);