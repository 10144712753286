import { API_ENDPOINTS } from "../config/apiEndpoints";
import { setUser } from "../state";
import { ROUTES } from "../config";
import api from "../utils";

const AEP_TO_CHECK_USER_EXIST = API_ENDPOINTS.CHECK_USER_EXIST_API;
const AEP_TO_SEND_OTP = API_ENDPOINTS.SEND_EMAIL_OTP_API;
const AEP_TO_VERIFY_OTP = API_ENDPOINTS.VERIFY_EMAIL_OTP_API;
const AEP_TO_SEND_MOBILE_OTP = API_ENDPOINTS.SEND_MOBILE_OTP_API;
const AEP_TO_VERIFY_MOBILE_OTP = API_ENDPOINTS.VERIFY_MOBILE_OTP_API;
const AEP_FOR_SIGNIN_WITH_GOOGLE = API_ENDPOINTS.GOOGLE_SIGN_IN_API;
const AEP_FOR_LOGOUT = API_ENDPOINTS.LOGOUT_API;


// Avoid using useCallback unless necessary
export const checkUserExist = async ({ email = null, mobile_number = null }) => {
    if (!email && !mobile_number) {
        throw new Error("Email or mobile number is required");
    }
    try {
        const response = await api.post(AEP_TO_CHECK_USER_EXIST, { email, mobile_number });
        return response.data.message.user_exist;
    } catch (err) {
        throw err;
    }
};

export const sendEmailOTP = async ({ email }) => {
    try {
        await api.post(AEP_TO_SEND_OTP, { email });
    } catch (error) {
        throw error;
    }
};

export const verifyEmailOTP = async ({ email, otp, dispatch, navigate }) => {
    try {
        const response = await api.post(AEP_TO_VERIFY_OTP, { email, otp }, { withCredentials: true });

        const { data } = response;
        dispatch(setUser(data.data));
        if (data.data.is_profile_completed) {
            navigate(ROUTES.HOME);
        } else {
            navigate(ROUTES.ADD_DETAILS);
        }

    } catch (error) {
        throw error;
    }
};

export const sendMobileOTP = async ({ mobile }) => {
    try {
        await api.post(AEP_TO_SEND_MOBILE_OTP, { mobile_number: mobile });
    } catch (error) {
        throw error;
    }
};


export const verifyMobileOTP = async ({ mobile, otp, dispatch, navigate }) => {

    try {
        const response = await api.post(AEP_TO_VERIFY_MOBILE_OTP, { mobile_number: mobile, otp }, { withCredentials: true });
        dispatch(setUser(response.data.data));

        if (response.data.data.is_profile_completed) {
            navigate(ROUTES.HOME);
        } else {
            navigate(ROUTES.ADD_DETAILS);
        }

    } catch (error) {
        throw error;
    }
};

export const googleLogin = async ({ tokenResponse, dispatch, navigate }) => {
    try {
        const accessToken = tokenResponse.access_token;
        const response = await api.post(AEP_FOR_SIGNIN_WITH_GOOGLE, {
            google_access_token: accessToken,
        }, { withCredentials: true });

        if (response.status === 200 || response.status === 201) {
            dispatch(setUser(response.data.data));
            if (response.data.data.is_profile_completed) {
                navigate(ROUTES.HOME);
            } else {
                navigate(ROUTES.ADD_DETAILS);
            }
        }
    } catch (err) {
        throw err;
    }
};

export const logout = async () => {
    try {
        await api.post(AEP_FOR_LOGOUT);
    } catch (err) {
        throw err;
    }
}