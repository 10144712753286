import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";

const HELPER_TEXT = {
  SHORT_OTP_LENGTH: 'OTP should be of 6 digits',
  SHORT_MOBILE_NUMBER: 'Mobile number should be of 10 digits',
  INVALID_MOBILE_NUMBER: 'Invalid mobile number',
  INVALID_EMAIL: 'Invalid email address',
  SHORT_NAME: 'Name should be at least 5 characters',
  SHORT_ADDRESS: 'Address should be at least 10 characters',
}
const LENGTH_OF_MOBILE_NUMBER = 10;
const MOBILE_OR_OTP_INPUT_PATTERN = '[0-9]*';
const INPUT_ADORMENT_POSITION = 'start';
const EMPTY = '';


export const NameInput = ({ userName, isSubmitting, name, setName, ...NAME_INPUT_PROPS }) => (
  <TextField
    {...NAME_INPUT_PROPS}
    value={name}
    onChange={(e) => setName(e.target.value)}
    helperText={(name?.length > 0 && name?.length < 5) ? HELPER_TEXT.SHORT_NAME : EMPTY}
    slotProps={{
      input: {
        readOnly: !!userName || isSubmitting,
      },
    }}
  />
);


export const MobileNumberInput = ({
  userMobile = false,
  isSubmitting,
  mobile,
  setMobile,
  isMobileValid,
  ...MOBILE_INPUT_PROPS
}) => {
  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value?.length <= 10) {
      setMobile(value);
    }
  };

  const isLengthValid = mobile?.length === LENGTH_OF_MOBILE_NUMBER;
  const helperText = !isLengthValid
    ? HELPER_TEXT.SHORT_MOBILE_NUMBER
    : !isMobileValid && mobile?.length > 0
      ? HELPER_TEXT.INVALID_MOBILE_NUMBER
      : EMPTY;

  return (
    <TextField
      {...MOBILE_INPUT_PROPS}
      value={mobile}
      onChange={handleChange}
      error={mobile?.length === LENGTH_OF_MOBILE_NUMBER && !isMobileValid}
      helperText={mobile?.length > 0 ? helperText : ''}
      slotProps={{
        input: {
          startAdornment: <InputAdornment position={INPUT_ADORMENT_POSITION}>+91</InputAdornment>,
          readOnly: !!userMobile||isSubmitting,
          maxLength: LENGTH_OF_MOBILE_NUMBER,
          pattern: MOBILE_OR_OTP_INPUT_PATTERN,
        },
      }}
    />
  );
};


export const EmailInput = ({ userEmail = false, isSubmitting, email, setEmail, isEmailValid, showEmail=true, handleClickShowEmail, handleMouseDownEmail, handleMouseUpEmail, ...EMAIL_INPUT_PROPS }) => (
  <TextField
    {...EMAIL_INPUT_PROPS}
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    helperText={!isEmailValid && email?.length > 0 ? HELPER_TEXT.INVALID_EMAIL : EMPTY}
    type={showEmail ? 'email' : 'password'}
    slotProps={{
      input: {
        readOnly: !!userEmail || isSubmitting,
        endAdornment: (
          < InputAdornment position="end" >
            <IconButton
              aria-label="toggle email visibility"
              onClick={handleClickShowEmail}
              onMouseDown={handleMouseDownEmail}
              onMouseUp={handleMouseUpEmail}
              edge="end"
              size="small"
              sx={{ color:'#bab5b5' }}
            >
              {showEmail ? <VisibilityOff fontSize="small"/> : <Visibility fontSize="small"/>}
            </IconButton>
          </InputAdornment >)
      },
    }}
  />
);

export const AddressInput = ({ address, isSubmitting, setAddress, ...ADDRESS_INPUT_PROPS }) => (
  <TextField
    {...ADDRESS_INPUT_PROPS}
    value={address}
    onChange={(e) => setAddress(e.target.value)}
    helperText={(address?.length > 0 && address?.length < 10) ? HELPER_TEXT.SHORT_ADDRESS : EMPTY}
    slotProps={{
      input: {
        readOnly: isSubmitting,
      },
    }}
  />
);


export const GatewayIdInput = ({ value, isSubmitting, onChange, ...props }) => {
  const handleInputChange = (event) => {
    const { value } = event.target;
    // Convert to uppercase and call the parent onChange
    onChange(value.toUpperCase());
  };

  return (
    <TextField
      value={value}
      onChange={handleInputChange}
      {...props}
      slotProps={{
        input: {
          readOnly: isSubmitting,
        },
      }}
    />
  );
};
