import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { TextField, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Autocomplete} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import styles from './UserDetailsForm.module.css';
import { validateEmail, validateMobileNumber } from '../../utils';
import { AddressInput, EmailInput, MobileNumberInput, NameInput } from '../inputFeilds';
import { getAllSites } from '../../apiCalls/sites';
import { ROUTES } from '../../config';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../../state';
import { submitProfileDetails } from '../../apiCalls/user';
import { GlobalBackdrop } from '..';

const ROLE_LABEL = 'Role:';
const CONSUMER_LABEL = 'Consumer';
const SITE_ADMIN_LABEL = 'Site Admin';
const SELECT_SITE_LABEL = 'Select site';
const SUBMIT_BUTTON_TEXT = 'Submit';

const ROLES = {
  CONSUMER: 'consumer',
  SITE_ADMIN: 'site_admin',
};

const TEXTFIELD_PROPS = {
  variant: 'outlined',
  margin: 'dense',
  fullWidth: true,
};

const NAME_INPUT_PROPS = {
  name: 'name',
  label: 'Name',
  type: 'text',
  required: true,
  ...TEXTFIELD_PROPS,
};

const EMAIL_INPUT_PROPS = {
  name: 'email',
  label: 'Email',
  type: 'email',
  required: true,
  ...TEXTFIELD_PROPS,
};

const MOBILE_INPUT_PROPS = {
  name: 'mobile_number',
  label: 'Mobile Number',
  type: 'numeric',
  ...TEXTFIELD_PROPS,
};

const ADDRESS_INPUT_PROPS = {
  name: 'address',
  label: 'Address',
  type: 'text',
  ...TEXTFIELD_PROPS,
};

const RADIO_BUTTON_PROPS = {
  size: 'small',
};

const ROLE_SELECTION_RADIO_GROUP_PROPS = {
  size: 'small',
  row: true,
};

const ROLE_SELECTION_FORM_CONTROL_PROPS = {
  component: 'fieldset',
  margin: 'normal',
};

const SELECT_SITE_PROPS = {
  ...TEXTFIELD_PROPS,
  name: 'site_id',
  required: true,
};

const SUBMIT_BUTTON_PROPS = {
  type: 'submit',
  variant: 'contained',
  color: 'primary',
};

const EMPTY = '';


const UserDetailsForm = React.memo(() => {
  const navigate=useNavigate();
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.user.email);
  const userName = useSelector((state) => state.user.name);
  const userMobile = useSelector((state) => state.user.mobileNumber);
  const [name, setName] = useState(userName || EMPTY);
  const [email, setEmail] = useState(userEmail || EMPTY);
  const [mobile, setMobile] = useState(userMobile || EMPTY);
  const [address, setAddress] = useState(EMPTY);
  const [role, setRole] = useState(ROLES.CONSUMER);
  const [dropdownValue, setDropdownValue] = useState(null);
  const [siteOptions, setSiteOptions] = useState([]);
  const [showEmail, setShowEmail] = useState(true);

  const handleClickShowEmail = () => setShowEmail((show) => !show);

  const handleMouseDownEmail = (event) => {
    event.preventDefault();
  };

  const handleMouseUpEmail = (event) => {
    event.preventDefault();
  };
  

  const isEmailValid = useMemo(() => validateEmail(email), [email]);
  const isMobileValid = useMemo(() => validateMobileNumber(mobile), [mobile]);
  const isNameValid = useMemo(() => name?.length >= 4, [name]);
  const isAddressValid = useMemo(() => address?.length >= 10, [address]);
  const [loading, setLoading] = useState(false);

  const isFormValid = useMemo(() => {
    return (
      isEmailValid && 
      isNameValid &&
      // If mobile is provided, it must be valid
      (mobile ? isMobileValid : true) &&
      // If address is provided, it must be valid
      (address ? isAddressValid : true)
    );
  }, [isEmailValid, isMobileValid, isNameValid, isAddressValid, mobile, address]);
  

  
  useEffect(() => {
    const fetchSites = async () => {
      try {
       setLoading(true);
        const response = await getAllSites();  // Await the async call
        setSiteOptions(response);
      } catch (error) {
        console.error('Error fetching site options:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchSites();
  },[]);

  const handleRoleChange = useCallback((e) => {
    const selectedRole = e.target.value;
    setRole(selectedRole);
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      const response = await submitProfileDetails({
        name,
        mobile_number: mobile,
        email,
        address,
        role,
        site_id: dropdownValue?.id,
      });
      dispatch(setUser(response));
       navigate(ROUTES.HOME);
      
    } catch (error) {
      console.error(error);
    }
  }, [name, mobile, email, address, role, dropdownValue, dispatch, navigate]);



  return (
    <form onSubmit={handleSubmit} className={styles.userDetailsForm}>
      <NameInput {...NAME_INPUT_PROPS} name={name} setName={setName}  />
      <EmailInput {...EMAIL_INPUT_PROPS} userEmail={userEmail} email={email} setEmail={setEmail}  isEmailValid={isEmailValid} showEmail={showEmail} handleClickShowEmail={handleClickShowEmail} handleMouseDownEmail={handleMouseDownEmail} handleMouseUpEmail={handleMouseUpEmail} />
      <MobileNumberInput {...MOBILE_INPUT_PROPS} userMobile={userMobile} mobile={mobile} setMobile={setMobile} isMobileValid={isMobileValid}  />
      <AddressInput {...ADDRESS_INPUT_PROPS} address={address} setAddress={setAddress}  />
      <SiteSelection value={dropdownValue} siteOptions={siteOptions} role={role} onChange={(event, newValue) => setDropdownValue(newValue)}  />
      <RoleSelection role={role} handleRoleChange={handleRoleChange} />

      <Button {...SUBMIT_BUTTON_PROPS} disabled={ !isFormValid} className={isFormValid ? EMPTY : styles.inactiveButton}>
         {SUBMIT_BUTTON_TEXT}
      </Button>

      <GlobalBackdrop loading={loading} />
    </form>
  );
});

const RoleSelection = ({ role, handleRoleChange }) => (
  <FormControl {...ROLE_SELECTION_FORM_CONTROL_PROPS}>
    <FormLabel>{ROLE_LABEL}</FormLabel>
    <RadioGroup value={role} onChange={handleRoleChange} {...ROLE_SELECTION_RADIO_GROUP_PROPS}>
      <FormControlLabel value={ROLES.CONSUMER} control={<Radio {...RADIO_BUTTON_PROPS} />} label={CONSUMER_LABEL} />
      <FormControlLabel value={ROLES.SITE_ADMIN} control={<Radio {...RADIO_BUTTON_PROPS} />} label={SITE_ADMIN_LABEL} />
    </RadioGroup>
  </FormControl>
);

const SiteSelection = ({ value, onChange, siteOptions }) => (
  <Autocomplete
    options={siteOptions}
    fullWidth
    getOptionLabel={(option) => option?.name}
    value={value}
    onChange={onChange}
    renderInput={(params) => (
      <TextField
        {...params}
        label={SELECT_SITE_LABEL}
        {...SELECT_SITE_PROPS}
      />
    )}
  />
);

export default UserDetailsForm;