import { API_ENDPOINTS } from "../config/apiEndpoints";
import api from "../utils";

const AEP_TO_COMPLETE_PROFILE = API_ENDPOINTS.COMPLETE_PROFILE_API;
const AEP_FOR_UPDATE_USER_DETAILS = API_ENDPOINTS.UPDATE_USER_PROFILE_API;
const AEP_FOR_GET_USER_DETAILS = API_ENDPOINTS.GET_USER_PROFILE_API;
const AEP_FOR_ROLE_CHANGE_REQUEST = API_ENDPOINTS.ROLE_CHANGE_REQUEST_API;
const AEP_FOR_DELETE_USER = API_ENDPOINTS.DELETE_USER_API;

export const submitProfileDetails = async ({ name, mobile_number, email, address, role, site_id }) => {
    try {
        const response = await api.post(
            AEP_TO_COMPLETE_PROFILE,
            {
                name,
                mobile_number,
                email,
                address,
                role,
                site_id,
            });
        return response.data.data;
    } catch (error) {
        throw error;
    }
};

export const getUserDetails = async ( userId ) => {
    try {
        const response = await api.get(`${AEP_FOR_GET_USER_DETAILS}${userId}`);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}

export const updateUserDetails = async ({ name, mobile_number, email, address}) => {
    try {
        const response = await api.post(
            AEP_FOR_UPDATE_USER_DETAILS,
            {
                name,
                mobile_number,
                email,
                address
            });
        return response.data.data;
    } catch (error) {
        throw error;
    }
}

export const roleChangeRequest=async({user_id,requested_role})=>{
    try{
        await api.post(`${AEP_FOR_ROLE_CHANGE_REQUEST}`,{
            user_id,
            requested_role
        })
    }catch(error){
        throw error;
    }
}

export const deleteUser = async ( userId ) => {
    try {
        await api.delete(`${AEP_FOR_DELETE_USER}${userId}`);
    } catch (error) {
        throw error;
    }
}