// components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../../config';

const ProtectedRoute = ({ isAuth, isProfileCompleted, children }) => {
  if (!isAuth) {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }
  if (!isProfileCompleted) {
    return <Navigate to={ROUTES.ADD_DETAILS} replace />;
  }
  return children;
};

export default ProtectedRoute;
