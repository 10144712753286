import React, { createContext, useState, useContext } from 'react';

const BackdropContext = createContext();

export const useBackdrop = () => useContext(BackdropContext);

export const BackdropProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  const showBackdrop = () => {
    setOpen(true);
  };
  const hideBackdrop = () => setOpen(false);

  return (
    <BackdropContext.Provider value={{ open, showBackdrop, hideBackdrop }}>
      {children}
    </BackdropContext.Provider>
  );
};
