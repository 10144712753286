// CustomList.js
import React from 'react';
import { List, ListItem, ListItemText, Checkbox, Card, CardHeader, Divider } from '@mui/material';
import styles from './CustomList.module.css';

const CustomList = ({ title, items, checked, handleToggle, handleToggleAll, numberOfChecked }) => {
    const listComponentType = 'div';
    const listComponentRole = 'list';

    return (
        <Card>
            <CardHeader
                className={styles.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List
                className={styles.deviceList}
                dense
                component={listComponentType}
                role={listComponentRole}
            >
                {items.map((device) => {
                    const labelId = `transfer-list-all-item-${device.id}-label`;

                    return (
                        <ListItem key={device.id} role="listitem" onClick={handleToggle(device.id)}>
                            <ListItemText id={labelId} primary={device.name} />
                            <Checkbox
                                checked={checked.includes(device.id)}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                    'aria-labelledby': labelId,
                                }}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </Card>
    );
};

export default CustomList;
