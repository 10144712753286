import React from 'react';
import { ROUTES } from './config';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Home, LoginPage, RegisterPage, AddDetailsPage, Dialog } from './pages';
import { DevicesUnderSite, UserProfile, ProtectedRoute, Help, Maintainance, IntimateDevices, RoleManagement, UserDevices, Consumer, DeviceManagement, SiteProfile, AllSites, DefaultDashboard, SiteRegistration, Update } from './components';


function App() {
  const user = useSelector((state) => state.user);
  const isMobile = useMediaQuery('(max-width: 900px)');
  const isAuth = Boolean(useSelector((state) => state.user.accessToken));
  const isProfileCompleted = Boolean(useSelector((state) => state.user.isProfileCompleted));


  return (
    <>
      <Routes>

        <Route
          path={ROUTES.HOME}
          element={
            <ProtectedRoute isAuth={isAuth} isProfileCompleted={isProfileCompleted}>
              <Home />
            </ProtectedRoute>
          }
        >

          <Route index element={<DefaultDashboard />} />
          <Route path={ROUTES.USER_DEVICES} element={<UserDevices userId={user.userId} />} />
          <Route path={ROUTES.USER_PROFILE} element={<UserProfile userId={user.userId} />} />
          <Route path={ROUTES.DEVICES} element={<DevicesUnderSite />} />
          <Route path={ROUTES.CONSUMERS} element={<Consumer />} />
          <Route path={ROUTES.DEVICE_MANANEMENT} element={<DeviceManagement />} />
          <Route path={ROUTES.MAINTAINANCE} element={<Maintainance />} />
          <Route path={ROUTES.INTIMATE_DEVICES} element={<IntimateDevices />} />
          <Route path={ROUTES.ROLE_MANAGEMENT} element={<RoleManagement />} />
          <Route path={ROUTES.SITE_PROFILE} element={<SiteProfile />} />
          <Route path={ROUTES.ALL_SITES} element={<AllSites />} />
          <Route path={ROUTES.SITE_REGISTRATION} element={<SiteRegistration />} />
          <Route path={ROUTES.UPDATES} element={<Update />} />
          {isMobile && <Route path={ROUTES.HELP} element={<Help />} />}

        </Route>


        <Route
          path={ROUTES.LOGIN}
          element={isAuth ? <Navigate to={ROUTES.HOME} replace /> : <LoginPage />}
        />
        <Route path={ROUTES.REGISTER} element={<RegisterPage />} />


        <Route
          path={ROUTES.ADD_DETAILS}
          element={
            <ProtectedRoute isAuth={isAuth} isProfileCompleted={true}>
              <AddDetailsPage />
            </ProtectedRoute>
          }
        />


        <Route
          path={ROUTES.SUPER_ADMIN}
          element={
            <ProtectedRoute isAuth={isAuth} isProfileCompleted={isProfileCompleted}>
              <Dialog />
            </ProtectedRoute>

          }
        >

          {/* <Route index element={<DefaultDashboard />} /> */}
          <Route path={ROUTES.UPDATES} element={<Update />} />
          <Route path={ROUTES.CONSUMERS} element={<Consumer />} />
          <Route path={ROUTES.SITE_PROFILE} element={<SiteProfile />} />
          <Route path={ROUTES.DEVICES} element={<DevicesUnderSite />} />
          <Route path={ROUTES.MAINTAINANCE} element={<Maintainance />} />
          <Route path={ROUTES.ROLE_MANAGEMENT} element={<RoleManagement />} />
          <Route path={ROUTES.INTIMATE_DEVICES} element={<IntimateDevices />} />
          <Route path={ROUTES.DEVICE_MANANEMENT} element={<DeviceManagement />} />
        </Route>

        {/* Fallback Route */}
        <Route path="*" element={<Navigate to={ROUTES.LOGIN} replace />} />

      </Routes>
    </>
  );
}

export default App;

