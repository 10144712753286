import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userId:'',
  name: '',
  email: '',
  role: '',
  siteId:'',
  isVerified: false,
  isProfileCompleted: false,
  accessToken: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      const {
        id,
        name,
        email,
        current_role,
        is_verified,
        is_profile_completed,
        site_id,
        access_token,
      } = action.payload;
      state.userId = id ?? state.userId;  // Keep existing value if not in payload
      state.name = name ?? state.name;
      state.email = email ?? state.email;
      state.role = current_role ?? state.role;
      state.siteId = site_id ?? state.siteId;
      state.isVerified = is_verified ?? state.isVerified;
      state.isProfileCompleted = is_profile_completed ?? state.isProfileCompleted;
      state.accessToken = access_token ?? state.accessToken;  // Retain current token if not provided
    },
    setLogout(state) {
      return initialState;
    },
  },
});


export const { setUser, setLogout } = userSlice.actions;

export default userSlice.reducer;
