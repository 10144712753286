import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { TextField, Button, Typography, CircularProgress, Divider, Tabs, Tab, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMessage, useSnackbarAlert, useTimer } from '../../hooks';
import styles from './AuthForm.module.css';
import GoogleButton from 'react-google-button';
import { validateEmail, validateMobileNumber, validateOTP } from '../../utils';
import { ROUTES } from '../../config';
import { EmailInput, MobileNumberInput } from '../inputFeilds';
import { checkUserExist, googleLogin, sendEmailOTP, sendMobileOTP, verifyEmailOTP, verifyMobileOTP } from '../../apiCalls/auth';
import { useDispatch } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';


// Authentication Types
const AUTH_TYPES = {
  EMAIL: 'email',
  MOBILE: 'mobile',
}
// Form Types
const FORM_TYPES = {
  LOGIN: 'login',
  REGISTER: 'register',
};

//Text Constants
const EMAIL_OTP_SENT_MESSAGE = `OTP sent. Please check your email.`;
const MOBILE_OTP_SENT_MESSAGE = `OTP sent. Please check your mobile.`;
const EMAIL_OTP_RESENT_MESSAGE = `OTP resent. Please check your email.`;
const MOBILE_OTP_RESENT_MESSAGE = `OTP resent. Please check your mobile.`;
const EMAIL_NOT_REGISTERED_MESSAGE = 'Email not registered. Please register.';
const EMAIL_ALREADY_REGISTERED_MESSAGE = 'Email already registered. Please login.';
const MOBILE_NOT_REGISTERED_MESSAGE = 'Mobile not registered. Please register.';
const MOBILE_ALREADY_REGISTERED_MESSAGE = 'Mobile already registered. Please login.';
const UNEXPECTED_RESPONSE_MESSAGE = 'Unexpected response. Please try again.';
const UNEXPECTED_ERROR_MESSAGE = 'Unexpected error. Please try again.';
const OTP_EXPIRED_MESSAGE = 'OTP expired. Please resend OTP.';
// const ERROR_CHECKING_REGISTRATION_MESSAGE = 'Error checking registration status.';
const REGISTER_LINK_TEXT = 'Not registered? Register here.';
const LOGIN_LINK_TEXT = 'Already registered? Login here.';
const GOOGLE_LOGIN_LABEL = 'Sign in with Google';
const GOOGLE_SIGNUP_LABEL = 'Sign up with Google';
const INVALID_OTP_MESSAGE = (validateOTPCount) => {
  const attemptsLeft = 3 - validateOTPCount;

  return validateOTPCount < 3
    ? `Invalid OTP. ${attemptsLeft} attempt${attemptsLeft === 1 ? '' : 's'} left. Please try again.`
    : 'Invalid OTP. Please resend.';
};

// Helper Text
const HELPER_TEXT = {
  SHORT_OTP_LENGTH: 'OTP should be of 6 digits'
}

// Constants for UI
const MESSAGE_TYPOGRAPHY_VARIANT = 'body2';
const AUTH_TAB_BOX_BOX_BORDER_COLOR = 'divider';
const AUTH_TAB_BOX_TABS_VARIANT = 'fullWidth';
const MOBILE_OR_OTP_INPUT_PATTERN = '[0-9]*';
const AUTH_TAB_BOX_BORDER_BOTTOM_VALUE = 1;
const LENGTH_OF_OTP = 6;
const TIME_FOR_OTP_RESEND = 60;
const CIRCULAR_PROGRESS_SIZE = 24;
const EMPTY = '';

const ALERT_SEVERITY = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
}

// Authentication Types
const AUTH_TABS_LABELS = {
  EMAIL: 'Email',
  MOBILE: 'Mobile',
}

// Button Props
const SEND_OTP_BUTTON_TEXT = 'Send OTP';
const VERIFY_OTP_BUTTON_TEXT = 'Verify OTP';
const RESEND_OTP_BUTTON_TEXT = 'Resend OTP';

const SEND_OTP_BUTTON_PROPS = {
  variant: 'contained',
  color: 'primary',
  type: 'submit',
};

const VERIFY_OTP_BUTTON_PROPS = {
  variant: 'contained',
  color: 'primary',
};

const RESEND_OTP_BUTTON_PROPS = {
  variant: 'contained',
  color: 'primary'
};

const GOOGLE_BUTTON_PROPS = {
  style: { width: '100%' },
};

const SWITCH_LINK_TYPOGRAPHY_PROPS = {
  variant: 'body2',
  align: 'center',
};

// TextField Component Props
const TEXTFIELD_PROPS = {
  fullWidth: true,
  required: true,
  margin: 'normal',
  variant: 'outlined',
};

const EMAIL_INPUT_PROPS = {
  ...TEXTFIELD_PROPS,
  label: 'Email',
  name: 'email',
};

const MOBILE_INPUT_PROPS = {
  ...TEXTFIELD_PROPS,
  label: 'Mobile Number',
  type: 'numeric',
  name: 'mobile',
};

const OTP_INPUT_PROPS = {
  ...TEXTFIELD_PROPS,
  label: 'OTP',
  type: 'numeric',
  name: 'otp',
};


const AuthForm = React.memo(({ formType }) => {
  // States
  const [email, setEmail] = useState(EMPTY);
  const [mobile, setMobile] = useState(EMPTY);
  const [otp, setOtp] = useState(EMPTY);
  const [isSending, setIsSending] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isResendingOtp, setIsResendingOtp] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [validateOTPCount, setValidateOTPCount] = useState(0);
  const [disableVerifyOTP, setDisableVerifyOTP] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [selectedTab, setSelectedTab] = useState(AUTH_TYPES.EMAIL);


  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isEmailValid = useMemo(() => validateEmail(email), [email]);
  const isMobileValid = useMemo(() => validateMobileNumber(mobile), [mobile]);
  const isOtpValid = useMemo(() => validateOTP(otp), [otp]);
  const { message, setMessage } = useMessage();
  const { timer, startTimer, resetTimer } = useTimer();
  const [showEmail, setShowEmail] = useState(true);
  const [resendOtpDisabled, setResendOtpDisabled] = useState(true);
  const {showSnackbar,SnackbarComponent} = useSnackbarAlert();

  const handleClickShowEmail = () => setShowEmail((show) => !show);

  const handleMouseDownEmail = (event) => {
    event.preventDefault();
  };

  const handleMouseUpEmail = (event) => {
    event.preventDefault();
  };

  // Callbacks
  const resetForm = useCallback(() => {
    setOtp(EMPTY);
    setEmail(EMPTY);
    setMobile(EMPTY);
    setIsOtpSent(false);
    setMessage(EMPTY);
    setValidateOTPCount(0);
    resetTimer();
  }, [resetTimer, setMessage]);

  const handleSendOTP = async (e) => {
    e.preventDefault();
    setMessage(EMPTY);
    setIsSending(true);

    try {
      const userExists = await checkUserExist({
        email: selectedTab === AUTH_TYPES.EMAIL ? email : null,
        mobile_number: selectedTab === AUTH_TYPES.MOBILE ? mobile : null,
      });
      if ((userExists === true && formType === FORM_TYPES.LOGIN) || (userExists === false && formType === FORM_TYPES.REGISTER)) {
        selectedTab === AUTH_TYPES.EMAIL ? await sendEmailOTP({ email }) : await sendMobileOTP({ mobile });
        const alertMessage= selectedTab === AUTH_TYPES.EMAIL ? EMAIL_OTP_SENT_MESSAGE : MOBILE_OTP_SENT_MESSAGE;
        showSnackbar(alertMessage,ALERT_SEVERITY.SUCCESS);
        setIsOtpSent(true);
        setDisableVerifyOTP(false);
        setResendOtpDisabled(true);
        startTimer(TIME_FOR_OTP_RESEND);
      } else if (userExists === true && formType === FORM_TYPES.REGISTER) {
        // setMessage(selectedTab === AUTH_TYPES.EMAIL ? EMAIL_ALREADY_REGISTERED_MESSAGE : MOBILE_ALREADY_REGISTERED_MESSAGE);
        const alertMessage= selectedTab === AUTH_TYPES.EMAIL ? EMAIL_ALREADY_REGISTERED_MESSAGE : MOBILE_ALREADY_REGISTERED_MESSAGE;
        showSnackbar(alertMessage,ALERT_SEVERITY.WARNING);
      } else if (userExists === false && formType === FORM_TYPES.LOGIN) {
        // setMessage(selectedTab === AUTH_TYPES.EMAIL ? EMAIL_NOT_REGISTERED_MESSAGE : MOBILE_NOT_REGISTERED_MESSAGE);
        const alertMessage= selectedTab === AUTH_TYPES.EMAIL ? EMAIL_NOT_REGISTERED_MESSAGE : MOBILE_NOT_REGISTERED_MESSAGE;
        showSnackbar(alertMessage,ALERT_SEVERITY.WARNING);
      } else {
        // setMessage(UNEXPECTED_RESPONSE_MESSAGE);
        showSnackbar(UNEXPECTED_RESPONSE_MESSAGE,ALERT_SEVERITY.ERROR);
      }
    } catch (err) {
      console.error(err);
      showSnackbar(UNEXPECTED_ERROR_MESSAGE,ALERT_SEVERITY.ERROR);
    } finally {
      setIsSending(false);
    }
  };

  const handleVerifyOTP = useCallback(async (e) => {
    e.preventDefault();
    setIsVerifying(true);
    const newValidateOTPCount = validateOTPCount + 1;
    setValidateOTPCount(newValidateOTPCount);
    try {
      selectedTab === AUTH_TYPES.EMAIL ? await verifyEmailOTP({ email, otp, dispatch, navigate }) : await verifyMobileOTP({ mobile, otp, dispatch, navigate });
      setValidateOTPCount(0);
    } catch (err) {
      // setMessage(UNEXPECTED_ERROR_MESSAGE);
      var alertMessage=EMPTY;
      if(err.response.status===400){
        alertMessage=INVALID_OTP_MESSAGE(newValidateOTPCount);
      } 
      else if(err.response.status===410){
        alertMessage=OTP_EXPIRED_MESSAGE;
      }
      else{
        alertMessage=err.response?.data?.message||UNEXPECTED_ERROR_MESSAGE;
      }
      showSnackbar(alertMessage,ALERT_SEVERITY.ERROR);
      console.error(err); 
    }
    finally {
      setIsVerifying(false);
      setOtp(EMPTY);
    }
  }, [selectedTab, dispatch, email, mobile, navigate, otp, validateOTPCount, showSnackbar]);

const handleResendOTP = async() => {
  try {
    setIsResendingOtp(true);
    setValidateOTPCount(0);
    selectedTab === AUTH_TYPES.EMAIL ? await sendEmailOTP({ email }) : await sendMobileOTP({ mobile });
    const alertMessage= selectedTab === AUTH_TYPES.EMAIL ? EMAIL_OTP_RESENT_MESSAGE : MOBILE_OTP_RESENT_MESSAGE;
    showSnackbar(alertMessage,ALERT_SEVERITY.SUCCESS);
    setResendOtpDisabled(true);
    setDisableVerifyOTP(false);
    startTimer(TIME_FOR_OTP_RESEND); 
  } catch (error) {
    console.error(error);
  } finally {
    setIsResendingOtp(false);
  }
}

  const handleGoogleLoginSuccess = async (tokenResponse) => {
    try {
      await googleLogin({ tokenResponse, dispatch, navigate });
    } catch (err) {
      console.error(err);
    }
  }
  const handleGoogleLogin = useGoogleLogin({ onSuccess: handleGoogleLoginSuccess });

  // Effects
  useEffect(() => {
    resetForm();
  }, [formType, resetForm]);

  useEffect(() => {
    if (validateOTPCount >= 3) {
      setDisableVerifyOTP(true);
      resetTimer();
    } 
  }, [validateOTPCount, setDisableVerifyOTP, resetTimer]);


  useEffect(() => {
    if (timer === 0) {
      setResendOtpDisabled(false);
      setOtp(EMPTY);
      resetTimer();
    }
  }, [timer, resetTimer]);

  // eslint-disable-next-line react/jsx-no-comment-textnodes
  return (
    <>
      <form onSubmit={isOtpSent ? handleVerifyOTP : handleSendOTP}>
        {/* <AuthTabBox selectedTab={selectedTab} setSelectedTab={setSelectedTab} resetForm={resetForm} /> */}
        {selectedTab === AUTH_TYPES.EMAIL && (
          <EmailInput {...EMAIL_INPUT_PROPS} email={email} setEmail={setEmail} isEmailValid={isEmailValid} showEmail={showEmail} handleClickShowEmail={handleClickShowEmail} handleMouseDownEmail={handleMouseDownEmail} handleMouseUpEmail={handleMouseUpEmail} isSubmitting={isOtpSent}/>
        )}
        {selectedTab === AUTH_TYPES.MOBILE && (
          <MobileNumberInput {...MOBILE_INPUT_PROPS} mobile={mobile} setMobile={setMobile} isMobileValid={isMobileValid} />
        )}
        {!isOtpSent ? (
          <SendOtpButton isEmailValid={isEmailValid} isMobileValid={isMobileValid} isSending={isSending} isCheckingUserExist={isSending} />
        ) : (
          <Typography variant={MESSAGE_TYPOGRAPHY_VARIANT}>
            {selectedTab === AUTH_TYPES.EMAIL ? EMAIL_OTP_SENT_MESSAGE : MOBILE_OTP_SENT_MESSAGE} Resend in {Math.floor(timer / 60)}:{('0' + (timer % 60)).slice(-2)}
          </Typography>
        )}
        <OtpInput otp={otp} setOtp={setOtp} isOtpSent={isOtpSent} timer={timer} isOtpValid={isOtpValid} handleVerifyOTP={handleVerifyOTP} disableVerifyOTP={disableVerifyOTP}/>
        <VerifyOtpButton isOtpSent={isOtpSent} isVerifying={isVerifying} handleVerifyOTP={handleVerifyOTP} disableVerifyOTP={disableVerifyOTP} />
        {isOtpSent && 
        <ResendOtpButton isResendingOtp={isResendingOtp} resendOtpDisabled={resendOtpDisabled} handleResendOTP={handleResendOTP} />}
        {message && (
          <Typography className={styles.errorMessage} variant={MESSAGE_TYPOGRAPHY_VARIANT}>
            {message}
          </Typography>
        )}
      </form>
      <Divider>or</Divider>
      <GoogleButton
        onClick={handleGoogleLogin}
        {...GOOGLE_BUTTON_PROPS}
        label={formType === FORM_TYPES.LOGIN ? GOOGLE_LOGIN_LABEL : GOOGLE_SIGNUP_LABEL}
      />
      <SwitchLink formType={formType} navigate={navigate} />
      {SnackbarComponent}
    </>
  );
});

// Form Component Functions

// eslint-disable-next-line no-unused-vars
const AuthTabBox = ({ selectedTab, setSelectedTab, resetForm }) => (
  <Box sx={{ borderBottom: AUTH_TAB_BOX_BORDER_BOTTOM_VALUE, borderColor: AUTH_TAB_BOX_BOX_BORDER_COLOR }} >
    <Tabs
      value={selectedTab}
      onChange={(_, newValue) => {
        setSelectedTab(newValue);
        resetForm();
      }}
      variant={AUTH_TAB_BOX_TABS_VARIANT}
    >
      <Tab label={AUTH_TABS_LABELS.EMAIL} value={AUTH_TYPES.EMAIL} />
      <Tab label={AUTH_TABS_LABELS.MOBILE} value={AUTH_TYPES.MOBILE} />
    </Tabs>
  </Box>
);

const OtpInput = ({ otp, setOtp, isOtpSent, isOtpValid, handleVerifyOTP,disableVerifyOTP }) => {
  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, EMPTY);
    if (value.length <= LENGTH_OF_OTP) {
      setOtp(value);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && otp.length === LENGTH_OF_OTP && isOtpValid) {
      handleVerifyOTP(e);
    }
  };

  return (
    <TextField
      {...OTP_INPUT_PROPS}
      value={otp}
      onChange={handleChange}
      onKeyDown={handleKeyPress}
      disabled={!isOtpSent||disableVerifyOTP}
      helperText={!isOtpValid && otp.length > 0 ? HELPER_TEXT.SHORT_OTP_LENGTH : EMPTY}
      slotProps={{
        input: {
          maxLength: LENGTH_OF_OTP,
          pattern: MOBILE_OR_OTP_INPUT_PATTERN
        }
      }}
    />
  )
};

const SendOtpButton = ({ isEmailValid, isMobileValid, isSending, isCheckingUserExist }) => (
  <Button
    {...SEND_OTP_BUTTON_PROPS}
    className={isEmailValid || isMobileValid ? EMPTY : styles.inactiveButton}
    disabled={isSending || isCheckingUserExist}
  >
    {isSending || isCheckingUserExist ? (
      <CircularProgress size={CIRCULAR_PROGRESS_SIZE} className={styles.loadingSpinner} />
    ) : (
      SEND_OTP_BUTTON_TEXT
    )}
  </Button>
);

const VerifyOtpButton = ({ isOtpSent, isVerifying, handleVerifyOTP, disableVerifyOTP }) => (

  <Button
    {...VERIFY_OTP_BUTTON_PROPS}
    onClick={handleVerifyOTP}
    disabled={!isOtpSent || isVerifying|| disableVerifyOTP}
  >
    {isVerifying ? (
      <CircularProgress size={CIRCULAR_PROGRESS_SIZE} className={styles.loadingSpinner} />
    ) : (
      VERIFY_OTP_BUTTON_TEXT
    )}
  </Button>
);

const ResendOtpButton = ({ resendOtpDisabled, handleResendOTP, isResendingOtp }) => (
  <Button
    {...RESEND_OTP_BUTTON_PROPS}
    disabled={resendOtpDisabled|| isResendingOtp}
    onClick={handleResendOTP}
    className={styles.resendOtpButton}
  >
     {isResendingOtp  ? (
      <CircularProgress size={CIRCULAR_PROGRESS_SIZE} className={styles.loadingSpinner} />
    ) : (
      RESEND_OTP_BUTTON_TEXT
    )}
  </Button>
);

const SwitchLink = ({ formType, navigate }) => (
  <Typography
    {...SWITCH_LINK_TYPOGRAPHY_PROPS}
    className={styles.link}
    onClick={() => formType === FORM_TYPES.LOGIN ? navigate(ROUTES.REGISTER) : navigate(ROUTES.LOGIN)}
  >
    {formType === FORM_TYPES.LOGIN ? REGISTER_LINK_TEXT : LOGIN_LINK_TEXT}
  </Typography>
);

export default AuthForm;
