import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography, FormControl, Grid2, Paper, Tooltip, Fab } from '@mui/material';
import { AddressInput, GatewayIdInput, NameInput } from '../inputFeilds';
import { Edit } from '@mui/icons-material';
import styles from './SiteProfile.module.css';
import { useOutletContext } from 'react-router-dom';
import { getSiteDetails, updateSiteDetails } from '../../apiCalls/sites';
import GlobalBackdrop from '../backdrop';
import { useSnackbarAlert } from '../../hooks';


const TEXTFIELD_PROPS = {
  fullWidth: true,
  variant: 'outlined',
};


const ADDRESS_INPUT_PROPS = {
  ...TEXTFIELD_PROPS,
  name: 'address',
  required: true,
  type: 'text',
};

const NAME_INPUT_PROPS = {
  ...TEXTFIELD_PROPS,
  name: 'name',
  required: true,
  type: 'text',
};

const ALERT_SEVERITY={
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
}

const EMPTY = '';
const TITLE = 'Site Profile';
const INPUT_FEILDS_LABELS = {
  SITE_NAME: 'Site Name*',
  GATEWAY_ID: 'Gateway ID',
  LOCATION: 'Location*',
}

const EDIT_BUTTON_TOOLTIP = {
  TITLE: 'Edit Details',
  PLACEMENT: 'bottom'
}

const FAB_ICON = {
  COLOR_PRIMARY: 'primary',
  COLOR_DEFAULT: 'default',
  ARIA_LABEL: 'edit',
}

const FORM_CONTROL_VARIANT = 'outlined';
const BUTTON_VARIANT = 'contained';
const BUTTON_COLOR = 'primary';
const BUTTON_TEXT = 'Save Changes';

const SUCCESS_MESSAGE = 'Site details updated successfully!';
const ERROR_MESSAGE = 'Error updating site details';

const SiteProfile = () => {
  const { siteId } = useOutletContext();

  // State variables
  const [editMode, setEditMode] = useState(false);
  const [siteName, setSiteName] = useState(EMPTY);
  const [gtwId, setGtwId] = useState(EMPTY);
  const [address, setAddress] = useState(EMPTY);
  const [loading, setLoading] = useState(false);
  const { showSnackbar, SnackbarComponent } = useSnackbarAlert();
  // Update form fields when site data is fetched

  const fetchSiteDetails = useCallback(async () => {
    if (!siteId) {
      return;
    }
    try {
      setLoading(true);
      const response = await getSiteDetails(siteId);
      setSiteName(response.name || EMPTY);
      setGtwId(response.gtw_id || EMPTY);
      setAddress(response.location || EMPTY);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [siteId]);

  useEffect(() => {
    fetchSiteDetails();
  }, [fetchSiteDetails]);

  // Validation
  const isNameValid = useMemo(() => siteName?.length >= 4, [siteName]);
  const isAddressValid = useMemo(() => address?.length >= 10, [address]);
  const isGtwIdValid = useMemo(() => gtwId?.length >= 15, [gtwId]);
  const isFormValid = useMemo(() => isNameValid && isAddressValid && (!gtwId || isGtwIdValid), [isNameValid, isAddressValid, isGtwIdValid, gtwId]);

  // Handlers
  const handleSubmit = async (event) => {
    event.preventDefault();
    const siteDetails = {
      site_name: siteName,
      gtw_id: gtwId,
      location: address,
      site_id: siteId
    };
    try {
      setLoading(true);
      await updateSiteDetails(siteDetails); // Call the update hook
      showSnackbar(SUCCESS_MESSAGE, ALERT_SEVERITY.SUCCESS);
      fetchSiteDetails();
    } catch (error) {
      showSnackbar(ERROR_MESSAGE, ALERT_SEVERITY.ERROR);
      console.error(error);
    } finally {
      setLoading(false);
      setEditMode(false);
    }
  };

  return (
    <Paper className={styles.userProfilePaper}>
      <Box className={styles.userProfileHeader}>
        <Typography className={styles.userProfileTitle}>
          {TITLE}
        </Typography>
        <Tooltip title={EDIT_BUTTON_TOOLTIP.TITLE} placement={EDIT_BUTTON_TOOLTIP.PLACEMENT}>
          <Fab
            color={editMode ? FAB_ICON.COLOR_PRIMARY : FAB_ICON.COLOR_DEFAULT}
            aria-label={EDIT_BUTTON_TOOLTIP.ARIA_LABEL}
            className={styles.userProfileEditButton}
            onClick={() => setEditMode(!editMode)}
          >
            <Edit />
          </Fab>
        </Tooltip>
      </Box>

      <form onSubmit={handleSubmit} className={styles.userProfileForm}>
        <Grid2 container spacing={3}>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <FormControl fullWidth variant={FORM_CONTROL_VARIANT}>
              <Typography className={styles.inputFeildsLabel}>
                {INPUT_FEILDS_LABELS.SITE_NAME}
              </Typography>
              <NameInput
                {...NAME_INPUT_PROPS}
                name={siteName}
                setName={setSiteName}
                isSubmitting={!editMode}
              />
            </FormControl>
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6 }}>
            <FormControl fullWidth variant={FORM_CONTROL_VARIANT}>
              <Typography className={styles.inputFeildsLabel}>
                {INPUT_FEILDS_LABELS.GATEWAY_ID}
              </Typography>
              <GatewayIdInput value={gtwId} onChange={(e) => setGtwId(e)} {...TEXTFIELD_PROPS} isSubmitting={!editMode} />
            </FormControl>
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6 }}>
            <FormControl fullWidth variant={FORM_CONTROL_VARIANT}>
              <Typography className={styles.inputFeildsLabel}>
                {INPUT_FEILDS_LABELS.LOCATION}
              </Typography>
              <AddressInput
                {...ADDRESS_INPUT_PROPS}
                address={address}
                setAddress={setAddress}
                isSubmitting={!editMode}
              />
            </FormControl>
          </Grid2>
        </Grid2>

        <Box className={styles.userProfileButtonContainer}>
          <Button
            variant={BUTTON_VARIANT}
            color={BUTTON_COLOR}
            onClick={handleSubmit}
            className={styles.userProfileButton}
            disabled={!isFormValid || !editMode}
          >
            {BUTTON_TEXT}
          </Button>
        </Box>
      </form>
      {loading && <GlobalBackdrop loading={loading} />}
      {SnackbarComponent}
    </Paper>
  );
};

export default SiteProfile;
