import React, { useCallback, useEffect, useState } from "react";
import { List, Paper, Typography, useMediaQuery } from "@mui/material";
import { AccountCircle, AppRegistration, AppSettingsAlt, Build, ChangeCircle, Devices, DevicesOther, Domain, Group, Help, Hub, Logout, SyncAlt, SystemUpdate } from "@mui/icons-material";
import { ROUTES } from "../../config";
import { useNavigate } from "react-router-dom";
import SidebarItem from "./SidebarItem";
import styles from "./Sidebar.module.css";
import { getSiteDetails } from "../../apiCalls/sites";
import GlobalBackdrop from "../backdrop";

const ROLE = {
  SITE_ADMIN: "site_admin",
  CONSUMER: "consumer",
  SUPER_ADMIN: "super_admin"
};

const LIST_ITEM_TEXT = {
  ALL_SITES: "All Sites",
  ALL_DEVICES: "All Devices",
  YOUR_DEVICES: "Your Devices",
  PROFILE: "Profile",
  CONSUMERS: "Consumers",
  HELP: "Help",
  LOGOUT: "Logout",
  ROLE_MANAGEMENT: "Role Management",
  DEVICE_MANANEMENT: "Device Management",
  MAINTAINANCE: "Maintainance",
  INTIMATE_DEVICES: "Intimate Devices",
  SITE_PROFILE: "Site Profile",
  SITE_REGISTRATION: "Site Registration",
  UPDATES: "Updates",
};

const LIST_COMPONENT_TYPE = {
  NAVIGATOR: "nav",
  DIV: "div",
};

const TITLE = (siteData) => {
  return `Site: ${siteData.name}`;
}


const Sidebar = ({ isDrawer, handleLogout, setIsMobileMenuToggled, role, siteId }) => {
  const navigate = useNavigate();
  const isHelpSection = useMediaQuery("(max-width: 900px)");
  const [siteData, setSiteData] = useState({});
  const [loading, setLoading] = useState(false);
  const fetchSiteDetails = useCallback(async () => {
    if (!siteId) {
      return;
    }
    try {
      setLoading(true);
      const response = await getSiteDetails(siteId);
      setSiteData(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [siteId]);

  useEffect(() => {
    fetchSiteDetails();
  }, [fetchSiteDetails]);

  const handleItemClick = (section) => {
    navigate(section);
    if (isDrawer) setIsMobileMenuToggled(false);
  };

  return (
    <Paper className={styles.sidebarContainer}>
      {(role === ROLE.SUPER_ADMIN && siteId) && (<Typography className={styles.title}>{TITLE(siteData)}</Typography>)}
      <List component={LIST_COMPONENT_TYPE.NAVIGATOR}>
        {(role === ROLE.SUPER_ADMIN && siteId === undefined) &&
          <>
            <SidebarItem icon={Domain} text={LIST_ITEM_TEXT.ALL_SITES} onClick={() => handleItemClick(ROUTES.ALL_SITES)} />
            <SidebarItem icon={SyncAlt} text={LIST_ITEM_TEXT.UPDATES} onClick={() => handleItemClick(ROUTES.UPDATES)} />
            <SidebarItem icon={AppRegistration} text={LIST_ITEM_TEXT.SITE_REGISTRATION} onClick={() => handleItemClick(ROUTES.SITE_REGISTRATION)} />
          </>
        }
        {(role === ROLE.CONSUMER || role === ROLE.SITE_ADMIN) && (
          <SidebarItem icon={Devices} text={LIST_ITEM_TEXT.YOUR_DEVICES} onClick={() => handleItemClick(ROUTES.USER_DEVICES)} />
        )}
        {((role === ROLE.SITE_ADMIN) || (role === ROLE.SUPER_ADMIN && siteId)) && (
          <>
            <SidebarItem icon={DevicesOther} text={LIST_ITEM_TEXT.ALL_DEVICES} onClick={() => handleItemClick(ROUTES.DEVICES)} />
            <SidebarItem icon={Group} text={LIST_ITEM_TEXT.CONSUMERS} onClick={() => handleItemClick(ROUTES.CONSUMERS)} />
            <SidebarItem icon={ChangeCircle} text={LIST_ITEM_TEXT.ROLE_MANAGEMENT} onClick={() => handleItemClick(ROUTES.ROLE_MANAGEMENT)} />
            <SidebarItem icon={AppSettingsAlt} text={LIST_ITEM_TEXT.DEVICE_MANANEMENT} onClick={() => handleItemClick(ROUTES.DEVICE_MANANEMENT)} />
            <SidebarItem icon={Build} text={LIST_ITEM_TEXT.MAINTAINANCE} onClick={() => handleItemClick(ROUTES.MAINTAINANCE)} />
            <SidebarItem icon={SystemUpdate} text={LIST_ITEM_TEXT.INTIMATE_DEVICES} onClick={() => handleItemClick(ROUTES.INTIMATE_DEVICES)} />
            <SidebarItem icon={Hub} text={LIST_ITEM_TEXT.SITE_PROFILE} onClick={() => handleItemClick(ROUTES.SITE_PROFILE)} />
          </>
        )}
        {(role === ROLE.CONSUMER || role === ROLE.SITE_ADMIN) && (

          <SidebarItem icon={AccountCircle} text={LIST_ITEM_TEXT.PROFILE} onClick={() => handleItemClick(ROUTES.USER_PROFILE)} />

        )}
        {isHelpSection && (
          <SidebarItem icon={Help} text={LIST_ITEM_TEXT.HELP} onClick={() => handleItemClick(ROUTES.HELP)} />
        )}
        {isDrawer && (
          <SidebarItem icon={Logout} text={LIST_ITEM_TEXT.LOGOUT} onClick={handleLogout} />
        )}
      </List>
      {loading && <GlobalBackdrop loading={loading} />}
    </Paper>
  );
};

export default Sidebar;

