import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Paper, Typography } from '@mui/material';
import styles from './DeviceUnderSite.module.css';
import { useNavigate } from 'react-router-dom';
import GlobalBackdrop from '../backdrop';
import { getAllSites } from '../../apiCalls/sites';

const TITLE = "Sites";

const DATA_GRID_COLUMNS_FIELDS = {
  SITE_NAME: "name",
  LOCATION: "location",
  GATEWAY: "gtw_id",
};

const DATA_GRID_COLUMNS_HEADER_NAME = {
  SITE_NAME: "Site Name",
  LOCATION: "Location",
  GATEWAY: "GTW ID",
};



const AllSites = () => {
  const navigate = useNavigate();
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSites = async () => {
      try {
        setLoading(true);
        const response = await getAllSites();  // Await the async call
        setSites(response);
      } catch (error) {
        console.error('Error fetching site options:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchSites();
  }, []);

  const columns = [
    { field: DATA_GRID_COLUMNS_FIELDS.SITE_NAME, headerName: DATA_GRID_COLUMNS_HEADER_NAME.SITE_NAME, flex: 1, minWidth: 150 },
    { field: DATA_GRID_COLUMNS_FIELDS.LOCATION, headerName: DATA_GRID_COLUMNS_HEADER_NAME.LOCATION, flex: 1, minWidth: 150 },
    { field: DATA_GRID_COLUMNS_FIELDS.GATEWAY, headerName: DATA_GRID_COLUMNS_HEADER_NAME.GATEWAY, flex: 1, minWidth: 150 },
  ];

  const handleRowClick = (params) => {
    navigate(`/super-admin/home/${params.row.id}`);
  };


  return (
    <Paper className={styles.devicePaper}>
      <Typography className={styles.title}>
        {TITLE}
      </Typography>
      <DataGrid
        rows={sites}
        columns={columns}
        pageSize={4}
        rowsPerPageOptions={[5, 6]}
        disableRowSelectionOnClick
        onRowClick={handleRowClick}
        autoHeight
      />
      {loading && <GlobalBackdrop loading={loading} />}
    </Paper>
  );
};

export default AllSites;
