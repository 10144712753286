import { Backdrop,CircularProgress } from "@mui/material";



const GlobalBackdrop = ({ loading=false }) => (
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
    >
        <CircularProgress color="inherit" />
    </Backdrop>
);

export default GlobalBackdrop;