import React, { useEffect, useMemo, useState } from 'react';
import { Button, Typography, Grid2, Paper, Box, Select, MenuItem, InputLabel, FormControl, FormHelperText } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import styles from './IntimateDevices.module.css';
import { useOutletContext } from 'react-router-dom';
import { getDevicesOfSite, intimateFirmwareVersion } from '../../apiCalls/device';
import GlobalBackdrop from '../backdrop';
import { getFirmwareVersionsOfSite } from '../../apiCalls/sites';

const MODE = {
    MAINTAINANCE: "maintenance",
    OPERATIONAL: "operational"
};

const BUTTON = {
    SIZE_SMALL: "small",
    VARIANT_CONTAINED: "contained",
    VARIANT_OUTLINED: "outlined"
};

const TITLE = "Intimate Devices";

const SUBMIT_BUTTON_TEXT = "Intimate Devices";

const DATA_GRID_COLUMNS_FEILDS = {
    NAME: "name",
    MODE: "mode",
    VERSION: "firmware_version",
}

const DATA_GRID_COULUMS_HEADER_NAME = {
    NAME: "Device Name",
    MODE: "Mode",
    VERSION: "Current Version",
}

const INPUT_LABEL_ID = "version-select-input-label";
const SELECT_LABEL_ID = "version-select-label";
const VERSION_SELECT_LABEL = "Select Version *";

const TEXT_NONE = "None";
const TEXT_REQUIRED = "Required";



const IntimateDevices = () => {
    const { siteId } = useOutletContext();
    const [selectedVersion, setSelectedVersion] = useState('');
    const [isVersionError, setIsVersionError] = useState(false);
    const [versions, setVersions] = useState([]);
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchDevices = async () => {
            try {
                setLoading(true);
                const devices = await getDevicesOfSite(siteId);
                setDevices(devices);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        const fetchFirmwareVersionsOfSite = async () => {
            try {
                setLoading(true);
                const versions = await getFirmwareVersionsOfSite(siteId);
                setVersions(versions);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);  
            }
        }

        fetchFirmwareVersionsOfSite();
        fetchDevices();
    }, [siteId]);



    const maintenanceDevices = useMemo(() => {
        return devices?.filter(device => device.mode === MODE.MAINTAINANCE);
    }, [devices]);



    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (!selectedVersion) {
                setIsVersionError(true);
                return;
            }
            await intimateFirmwareVersion({version:selectedVersion, siteId});
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };



    const columns = [
        { field: DATA_GRID_COLUMNS_FEILDS.NAME, headerName: DATA_GRID_COULUMS_HEADER_NAME.NAME, flex: 1, minWidth: 150 },
        { field: DATA_GRID_COLUMNS_FEILDS.MODE, headerName: DATA_GRID_COULUMS_HEADER_NAME.MODE, flex: 1, minWidth: 150 },
        { field: DATA_GRID_COLUMNS_FEILDS.VERSION, headerName: DATA_GRID_COULUMS_HEADER_NAME.VERSION, flex: 1, minWidth: 150 },
    ];

    return (
        <Paper className={styles.maintainancePaper}>
            <Box className={styles.titleContainer}>
                <Typography className={styles.title}>
                    {TITLE}
                </Typography>
                <VersionSelect
                    versions={versions}
                    selectedVersion={selectedVersion}
                    setSelectedVersion={setSelectedVersion}
                    isVersionError={isVersionError}
                    setIsVersionError={setIsVersionError}
                />
            </Box>

            <DataGrid
                rows={maintenanceDevices}
                columns={columns}
                disableSelectionOnClick
                disableRowSelectionOnClick
                autoHeight
            />

            <Grid2 container className={styles.submitButton}>
                <Button
                    variant={BUTTON.VARIANT_CONTAINED}
                    size={BUTTON.SIZE_SMALL}
                    onClick={handleSubmit}
                    disabled={!selectedVersion || maintenanceDevices.length === 0}
                >
                    {SUBMIT_BUTTON_TEXT}
                </Button>
            </Grid2>
            {loading && <GlobalBackdrop loading={loading} />}
        </Paper>
    );
};

export default IntimateDevices;


const VersionSelect = ({ versions, selectedVersion, setSelectedVersion, isVersionError, setIsVersionError }) => {
    return (
        <FormControl
            variant={BUTTON.VARIANT_OUTLINED}
            size={BUTTON.SIZE_SMALL}
            className={styles.versionSelect}
            error={isVersionError}
        >
            <InputLabel id={INPUT_LABEL_ID}>{VERSION_SELECT_LABEL}</InputLabel>
            <Select
                labelId={SELECT_LABEL_ID}
                value={selectedVersion}
                onChange={(e) => {
                    setSelectedVersion(e.target.value);
                    setIsVersionError(false);
                }}
                label={VERSION_SELECT_LABEL}
            >
                <MenuItem value=""><em>{TEXT_NONE}</em></MenuItem>
                {versions && versions?.map((version) => (
                    <MenuItem key={version} value={version}>{version}</MenuItem>
                ))}
            </Select>
            {!selectedVersion && <FormHelperText>{TEXT_REQUIRED}</FormHelperText>}
        </FormControl>
    );
};