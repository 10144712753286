import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Typography, Paper, Dialog, DialogContent, Tabs, Tab, Box, IconButton, DialogTitle} from "@mui/material";
import UserDevices from "../userDevices";
import UserProfile from "../userProfile"; 
import styles from "./ConsumersUnderSite.module.css";
import { Close } from "@mui/icons-material";
import { useOutletContext } from "react-router-dom";
import { getConsumersOfSite } from "../../apiCalls/sites";
import GlobalBackdrop from "../backdrop";

const DATA_GRID_COLUMNS_FEILDS={
  NAME: "name",
  EMAIL: "email",
  CURRENT_ROLE: "current_role",
  DEVICES: "devices",
}

const DATA_GRID_COULUMS_HEADER_NAME={
  NAME: "Username",
  EMAIL: "User Email",
  CURRENT_ROLE: "Current Role",
  DEVICES: "No. of Devices",
}

const TAB_LABELS = {
  USER_PROFILE: "User Profile",
  DEVICES: "Devices",
}

const TITLE= "Consumers Under Site";
const NO_CONSUMERS = "No Consumers Found";
const DAILOG_CLOSE_REASON = "backdropClick";

const DAILOG_TITLE_ID= "consumer-dialog-title";
const TABS_ARIA_LABEL = "User Profile and Devices Tabs";
const ICON_BUTTON_ARIA_LABEL = "close";

const Consumers = () => {
  const {siteId}=useOutletContext();
  const [selectedConsumer, setSelectedConsumer] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [consumers, setConsumers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchConsumers = async () => {
      try {
        setLoading(true);
        const response = await getConsumersOfSite(siteId); 
        setConsumers(response);
      } catch (error) { 
        console.error('Error fetching consumers:', error);
      } finally {
        setLoading(false);
      } 
    };
    fetchConsumers();
  }, [siteId]);


  const handleRowClick = (params) => {
    setSelectedConsumer(params.row); 
    setOpenModal(true);
  };


  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedConsumer(null);
  };



  const columns = [
    { field: DATA_GRID_COLUMNS_FEILDS.NAME, headerName: DATA_GRID_COULUMS_HEADER_NAME.NAME, flex: 1 },
    { field: DATA_GRID_COLUMNS_FEILDS.EMAIL, headerName: DATA_GRID_COULUMS_HEADER_NAME.EMAIL, flex: 1 },
    { field: DATA_GRID_COLUMNS_FEILDS.CURRENT_ROLE, headerName: DATA_GRID_COULUMS_HEADER_NAME.CURRENT_ROLE, flex: 1 },
    {
      field: DATA_GRID_COLUMNS_FEILDS.DEVICES,
      headerName: DATA_GRID_COULUMS_HEADER_NAME.DEVICES,
      flex: 1,
      valueGetter: (params) => {
        const devices = params || [];
        return devices?.length;
      },
    },
  ];

  return (
    <Paper className={styles.consumerPaper}>
      <Typography className={styles.title}>{TITLE}</Typography>
      {consumers?.length === 0 ? (
        <Typography>{NO_CONSUMERS}</Typography>
      ) : (
        <DataGrid
          rows={consumers}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 6]}
          disableRowSelectionOnClick
          onRowClick={handleRowClick}
          autoHeight
        />
      )}
      <ConsumerDialog
        open={openModal}
        onClose={handleCloseModal}
        selectedConsumer={selectedConsumer}
      />
     {loading && <GlobalBackdrop loading={loading} />}
    </Paper>
  );
};

export default Consumers;

const ConsumerDialog = ({ open, onClose, selectedConsumer }) => {
  const [tabIndex, setTabIndex] = React.useState(0); // State to manage the current tab

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== DAILOG_CLOSE_REASON) {
          onClose();
        }
      }}
      fullWidth
      PaperProps={{ className: styles.dialogPaper }}
    >
      <DialogTitle id={DAILOG_TITLE_ID}>
        <Box className={styles.tabBox}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label={TABS_ARIA_LABEL}
          >
            <Tab label={TAB_LABELS.USER_PROFILE} />
            <Tab label={TAB_LABELS.DEVICES} />
          </Tabs>
        <IconButton
          aria-label={ICON_BUTTON_ARIA_LABEL}
          onClick={onClose}
        >
          <Close />
        </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        {tabIndex === 0 && selectedConsumer && (
          <UserProfile userId={selectedConsumer.id} />
        )}
        {tabIndex === 1 && selectedConsumer && (
          <UserDevices userId={selectedConsumer.id} />
        )}
      </DialogContent>
    </Dialog>
  );
};

